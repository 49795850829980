import qs from 'querystring';

import { fetchAndRedirect } from '../helpers/api';

export function fetch_token(path, params) {
  let csrfToken = document.querySelector('[name="csrf-token"]').content;
  let headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken,
  };

  let url = path + '?' + qs.stringify(params);
  return fetchAndRedirect(url, {
    method: 'GET',
    headers: headers,
    credentials: 'include',
  });
}
