/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CarnegieLearningLogo = ({ size = '26.5px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.4 275.5" height={size} width={size}>
      <circle cx="137" cy="137" r="130" fill="#fff" />
      <path
        d="M221 178h-50.3c-2.9 0-5.2 2.3-5.2 5.2v8.9c0 2.9 2.3 5.2 5.2 5.2H221c2.9 0
        5.2-2.3 5.2-5.2v-8.9c0-2.9-2.3-5.2-5.2-5.2z"
        fill="#00bce7"
      />
      <path
        d="M138.4 1.7c-75.1 0-136 60.9-136 136s60.9 136 136 136 136-60.9 136-136-60.9-136-136-136zm87.8
        190.4c0 2.9-2.3 5.2-5.2 5.2h-50.3c-2.9 0-5.2-2.3-5.2-5.2v-8.9c0-2.9 2.3-5.2 5.2-5.2H221c2.9 0
        5.2 2.3 5.2 5.2v8.9zM165.5 88.3c0-4.4 2.2-6.6 6.6-6.6h5.7c4.4 0 6.6 2.2 6.6 6.6v48.2c0 4.4 2.2
        6.5 6.6 6.5h28.5c4.4 0 6.6 2.2 6.6 6.6v5.8c0 4.4-2.2 6.6-6.6 6.6H172c-4.4 0-6.6-2.2-6.6-6.6V88.3zm-94.7
        69.8c3.5 6.2 8.3 11.2 14.3 14.8 6 3.6 12.6 5.5 19.8 5.5 8.8 0 16.7-2.7 23.5-8.1l3.8-3c.8-.6 1.6-.9
        2.4-.9 1.6 0 3.3 1.2 5.2 3.6l3.3 4.4c1.1 1.5 1.6 2.9 1.6 4.2 0 1.8-1 3.5-2.9 5l-2.2 1.9c-10.3 7.9-21.9
        11.9-34.8 11.9-10.5 0-20.1-2.7-29-8-8.9-5.3-15.9-12.5-21.1-21.7-5.2-9.1-7.8-19.1-7.8-30 0-10.8 2.6-20.8
        7.8-30 5.3-9 12.3-16.3 21.2-21.6 8.9-5.3 18.6-8 29-8 12.9 0 24.5 4 34.8 11.9l3.6 2.8c1.2 1 1.8 2.2 1.8
        3.6s-.6 3-1.9 4.8l-3.3 4.4c-1.6 2-3.2 3-5 3-1.3 0-2.7-.6-4.2-1.7l-2.2-1.6c-7-5.4-14.8-8.2-23.5-8.2-7.2
        0-13.8 1.8-19.8 5.5-6 3.6-10.8 8.6-14.3 14.8-3.5 6.2-5.3 13-5.3 20.3-.1 7.4 1.7 14.2 5.2 20.4z"
        fill="#010101"
      />
    </svg>
  );
};

CarnegieLearningLogo.propTypes = {
  size: PropTypes.string,
};

export default React.memo(CarnegieLearningLogo);
