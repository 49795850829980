import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ButtonSubmit, Input } from '@flipgrid/flipkit';

import { addFocusListener, removeFocusListener } from '../../helpers/focusListener';
import { fetchAndRedirect } from '../../helpers/api';
import { validateDOB } from '../../actions/signup.actions';
import Announcer from '../Shared/Announcer';
import BirthdayPicker from '../Shared/BirthdayPicker';
import CountryPicker from '../Shared/CountryPicker';
import Navbar from '../Shared/Navbar';
import { flowStates } from '../../constants/states';

const BIRTHDAY_CAKE = 'https://static.flipgrid.com/fg-pngs/birthday-cake.png';

const AgeConfirmation = ({
  user,
  profile_submit_path: profileSubmitPath,
  profile_submit_method: profileSubmitMethod,
  profile_submit_options: profileSubmitOptions = {},
}) => {
  useEffect(() => {
    addFocusListener();
    return removeFocusListener;
  }, [addFocusListener, removeFocusListener]);

  const { t } = useTranslation();
  const [first_name, setFirstName] = useState(user.first_name);
  const [last_name, setLastName] = useState(user.last_name);
  const [dob, setDob] = useState(null);
  const [country_code, setCountryCode] = useState(user.country_code);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const confirmAge = e => {
    e.preventDefault();
    setSubmitted(true);
    const validateAgeErrors = validateDOB(dob);
    if (validateAgeErrors) {
      setError(validateAgeErrors);
      setSubmitted(false);
    } else {
      // If validateAge does not return error string, proceed with confirmation.
      const csrfToken = document.querySelector('[name="csrf-token"]');
      if (csrfToken && csrfToken.content) {
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        };
        const formData = {
          first_name,
          last_name,
          dob,
          country_code,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...profileSubmitOptions,
        };

        fetchAndRedirect(profileSubmitPath, {
          method: profileSubmitMethod,
          headers: headers,
          credentials: 'include',
          body: JSON.stringify(formData),
        });
      }
    }
  };

  return (
    <div className="ageConfirmation">
      <Navbar flow={flowStates.signUp} />
      <div className="ageConfirmation__panel">
        <img alt="" className="accountVerification__img" src={`${BIRTHDAY_CAKE}?size=small`} />
        <h1 className="fk-h2 ageConfirmation__h1">{t('ageConfirmation.confirmYourBirthday')}</h1>
        <p className="ageConfirmation__text">{t('ageConfirmation.weNeedToVerify')}</p>
        <form onSubmit={confirmAge}>
          {!user.first_name ? (
            <Input
              id="ageConfirmation-first-name"
              type="name"
              name="first_name"
              label={t('common.firstName')}
              placeholder={t('shared.enterYourFirstName')}
              wrapperClassName="ageConfirmation__formInput"
              maxLength={255}
              onChange={e => setFirstName(e.target.value)}
              value={first_name || ''}
            />
          ) : null}
          {!user.last_name ? (
            <Input
              id="ageConfirmation-last-name"
              type="name"
              name="last_name"
              label={t('common.lastName')}
              placeholder={t('shared.enterYourLastName')}
              wrapperClassName="ageConfirmation__formInput"
              maxLength={255}
              onChange={e => setLastName(e.target.value)}
              value={last_name || ''}
            />
          ) : null}
          <BirthdayPicker
            className="ageConfirmation__formInput"
            error={error}
            onChange={value => {
              setDob(value);
            }}
          />
          <CountryPicker wrapperClassName="ageConfirmation__formInput" value={country_code} onChange={setCountryCode} />
          <ButtonSubmit
            data-testid="ageConfirmation__button__submit"
            disabled={!first_name || !last_name || !dob || !country_code}
            loading={submitted}
            theme="primary"
            variant="block"
          >
            {t('common.next')}
          </ButtonSubmit>
        </form>
      </div>
      <Announcer>{submitted && t('shared.preparingYourFlipExperience')}</Announcer>
    </div>
  );
};

AgeConfirmation.propTypes = {
  user: PropTypes.object.isRequired,
  profile_submit_path: PropTypes.string.isRequired,
  profile_submit_method: PropTypes.string.isRequired,
  profile_submit_options: PropTypes.object,
};

export default AgeConfirmation;
