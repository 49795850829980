import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { Anchor, ButtonSubmit, Input, Loader, Smubble } from '@flipgrid/flipkit';

import { flowStates } from '../../constants/states';
import { requestEmailLogin } from '../../actions/auth.actions';

import AppleSsoButton from './components/AppleSsoButton';
import GoogleSsoButton from './components/GoogleSsoButton';
import MicrosoftSsoButton from './components/MicrosoftSsoButton';
import Navbar from '../Shared/Navbar';
import TermsAndPrivacy from '../Shared/TermsAndPrivacy';
import { getRedirectUrlParam } from '../../helpers/urls';

const VALID_REFERRERS = ['apple', 'google', 'microsoft'];

/*
Login Statuses:
  idle - nothing happening in the background
  customEmail:submitting - awaiting custom email api response
  customEmail:submitted - custom email api response successful
  sso:submitted - awaiting redirect post-sso response
*/

// emailLogin signifies that we're attempting to log into my.flipgrid
const SignIn = ({ authId, idpClientIds, emailLogin }) => {
  const { t } = useTranslation();
  const [customEmailError, setCustomEmailError] = useState(null);
  const [email, setEmail] = useState('');
  const [loginStatus, setLoginStatus] = useState('idle');

  const referrer = useMemo(() => {
    const search_str = window.location.search.substring(1).replace(/\/$/, '');
    const params = qs.parse(search_str);
    const referrer = params['referrer'] || '';

    return VALID_REFERRERS.find(ref => referrer.match(ref)) || '';
  }, []);
  const buttons = [];

  const submitCustomEmailForm = e => {
    e.preventDefault();
    if (loginStatus === 'customEmail:submitting') return;

    if (!email) {
      setCustomEmailError(t('signIn.youMustProvideYourEmail'));
      return;
    }

    setLoginStatus('customEmail:submitting');
    requestEmailLogin(email)
      .then(response => {
        if (response.status === 204) {
          setLoginStatus('customEmail:submitted');
        } else {
          setCustomEmailError(t('signIn.somethingWentWrongPleaseCheckYourEmail'));
          setLoginStatus('idle');
        }
      })
      .catch((...args) => {
        setCustomEmailError(t('signIn.somethingWentWrongPleaseCheckYourEmail'));
        setLoginStatus('idle');
      });
  };

  if (!!referrer) {
    switch (referrer) {
      case 'microsoft':
        buttons.push(
          <MicrosoftSsoButton key="microsoft" hide authId={authId} clientId={idpClientIds.microsoft} flow="redirect" />,
        );
        break;
      case 'google':
        buttons.push(
          <GoogleSsoButton key="google" hide authId={authId} clientId={idpClientIds.google} flow="redirect" />,
        );
        break;
      case 'apple':
        buttons.push(<AppleSsoButton key="apple" hide authId={authId} clientId={idpClientIds.apple} flow="redirect" />);
        break;
      default:
        break;
    }
  } else {
    buttons.push(
      <MicrosoftSsoButton
        ariaLabel={t('shared.signInWithMicrosoft')}
        authId={authId}
        clientId={idpClientIds.microsoft}
        flow="redirect"
        key="microsoft"
        text={t('shared.continueWithMicrosoft')}
      />,
    );

    buttons.push(
      <GoogleSsoButton
        ariaLabel={t('shared.signInWithGoogle')}
        authId={authId}
        clientId={idpClientIds.google}
        flow="redirect"
        key="google"
        text={t('shared.continueWithGoogle')}
      />,
    );

    buttons.push(
      <div className="signIn__googleLoginIssues">
        <Trans i18nKey="signIn.studentGoogleLoginIssues">
          Having issues with your Student Google login?
          <Anchor
            data-testid="signIn__anchor__googleSignupProblems"
            to="https://help.flip.com/hc/en-us/articles/17005477431959-Enable-Flip-for-use-with-Google-Workspace-for-Education-"
            newTab
          >
            Share this link with your teacher!
          </Anchor>
        </Trans>
      </div>,
    );

    buttons.push(
      <AppleSsoButton
        ariaLabel={t('shared.signInWithApple')}
        authId={authId}
        clientId={idpClientIds.apple}
        flow="redirect"
        key="apple"
        text={t('shared.continueWithApple')}
      />,
    );
  }

  const login =
    loginStatus.indexOf('submitted') > -1 ? (
      loginStatus === 'customEmail:submitted' ? (
        <>
          <h1>{t('shared.emailSent')}</h1>
          <p>{t('signIn.checkYourInbox')}</p>
        </>
      ) : (
        <>
          <Loader container className="signIn__loader" />
          <p>{t('signIn.preparingYourFlipExperience')}</p>
        </>
      )
    ) : (
      <>
        <Smubble height={80} className="showBelowSmall" />
        <h1 className="signIn__header">{t('common.signIn')}</h1>
        <div className="signIn__buttons">{buttons}</div>
        {emailLogin && (
          <>
            <p className="fk-helpText signIn__divider">{t('signIn.orRequestAccessByEmail')}</p>
            <form onSubmit={submitCustomEmailForm} className="signIn__customEmailForm">
              <Input
                id="signIn-custom-email"
                type="email"
                name="emailAddress"
                label={t('common.emailAddress')}
                placeholder={t('common.emailAddress')}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                value={email}
                error={customEmailError}
              />
              <ButtonSubmit
                theme="primary"
                className="signIn__submit"
                data-testid="signIn__button__submitCustomEmailRequest"
                variant="block"
                size="52"
                loading={loginStatus === 'customEmail:submitting'}
              >
                {t('common.go')}
              </ButtonSubmit>
            </form>
          </>
        )}
        {/* TODO: move to shared and get approved by translation team after break */}
        <Anchor
          className="signIn__differentEmail"
          data-testid="signIn__anchor__educatorSignInLink"
          to={
            getRedirectUrlParam() ? `/educator?redirect_url=${encodeURIComponent(getRedirectUrlParam())}` : `/educator`
          }
          theme="primary"
        >
          {t('signInTab.signInWithADifferentEmail')}
        </Anchor>
        <TermsAndPrivacy />
      </>
    );

  return (
    <div className="signIn">
      <Navbar flow={flowStates.signUp} />
      <div className="signIn__wrapper">
        <div className="signIn__flow">
          <div className="signUp__welcome">
            <img
              className="signUp__welcomeImg"
              alt=""
              src="https://static.flip.com/fg-pngs/LeadSignIn.png?size=medium"
            />
          </div>
          <div className="signIn__panel">{login}</div>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  authId: PropTypes.string.isRequired,
  idpClientIds: PropTypes.object.isRequired,
  emailLogin: PropTypes.bool,
};

export default SignIn;
