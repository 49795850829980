import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonSubmit } from '@flipgrid/flipkit';

import Navbar from '../Shared/Navbar';
import PasswordInput from '../Shared/PasswordInput';
import PasswordRequirements from '../Shared/PasswordRequirements';

import { updatePassword } from '../../actions/auth.actions';
import { isPasswordSecure } from '../../helpers/forms';
import { addFocusListener, removeFocusListener } from '../../helpers/focusListener';

function ResetPassword({ resetToken }) {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    addFocusListener();
    return removeFocusListener;
  }, [addFocusListener, removeFocusListener]);

  const validate = () => {
    const errors = {};

    if (!newPassword) errors.newPassword = t('resetPassword.newPasswordIsRequired');
    if (newPassword && !isPasswordSecure(newPassword)) errors.newPassword = t('resetPassword.newPasswordIsNotSecure');
    if (!confirmPassword) errors.confirmPassword = t('resetPassword.passwordConfirmationIsRequired');
    if (newPassword !== confirmPassword) errors.confirmPassword = t('resetPassword.passwordAndConfirmationMustMatch');

    return errors;
  };

  const submit = e => {
    e.preventDefault();

    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    setSubmitting(true);
    updatePassword({
      reset_password_token: resetToken,
      password: newPassword,
      password_confirmation: confirmPassword,
    }).then(response => {
      setSubmitting(false);
      if (response && response.errors && response.errors.password) {
        setErrors({ newPassword: response.errors.password });
      }
    });
  };

  return (
    <div className="resetPassword">
      <Navbar />
      <div className="resetPassword__panel">
        <h1>{t('resetPassword.resetPassword')}</h1>
        <form onSubmit={submit} className="resetPassword__form">
          <PasswordInput
            id="signup-new-password"
            label={t('resetPassword.newPassword')}
            error={errors.newPassword}
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
          <PasswordRequirements />
          <PasswordInput
            id="signup-confirm-password"
            label={t('resetPassword.confirmNewPassword')}
            error={errors.confirmPassword}
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
            }}
            autoComplete="new-password"
            wrapperClassName="resetPassword__input"
          />
          <ButtonSubmit
            theme="primary"
            className="resetPassword__submit"
            data-testid="resetPassword__button__submit"
            variant="block"
            size="52"
            loading={submitting}
          >
            {t('resetPassword.resetPassword')}
          </ButtonSubmit>
        </form>
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  resetToken: PropTypes.string.isRequired,
};

export default ResetPassword;
