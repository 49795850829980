import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, ErrorHandler, IcFluentQuestionCircle24Regular, Input, Option, Select } from '@flipgrid/flipkit';

import { BIRTHDAY_MIN_YEAR } from '../../constants/constants';
import Popover from '../Popover/Popover';

const BirthdayPicker = ({ className, error, onChange }) => {
  const { t } = useTranslation();
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const inputDescribedBy = `${error ? 'birthdayPicker-error' : ''}`;

  const months = useMemo(
    () =>
      [...Array(12).keys()].map(n => (
        <Option key={n} value={(n + 1).toString()}>
          {t(`months.${n + 1}`)}
        </Option>
      )),
    [],
  );

  useEffect(() => {
    // pad start with zero to conform with the expected MM/DD/YYYY date format
    onChange(`${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`);
  }, [month, day, year]);

  const Label = () => {
    const [showPopover, setShowPopover] = useState(false);
    const popoverButtonRef = useRef(null);
    const birthdayPickerLabelPopover = 'birthdayPicker-label-popover';
    return (
      <div className={'birthdayPicker__label' + (error ? ' -error' : '')}>
        <label className={'fk-externalLabel birthdayPicker__label' + (error ? ' -error' : '')}>
          {t('birthdayPicker.dateOfBirth')}
        </label>
        <Button
          aria-label={t('birthdayPicker.selectToLearnMore')}
          aria-describedby={showPopover ? birthdayPickerLabelPopover : ''}
          data-testid="birthdayPicker__label"
          className="birthdayPicker__popoverButton"
          icon={
            <IcFluentQuestionCircle24Regular width="16" height="16" fill={error ? 'var(--fk-color__error)' : null} />
          }
          onClick={event => {
            setShowPopover(true);
            event.preventDefault();
          }}
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={() => setShowPopover(false)}
          ref={popoverButtonRef}
          size="26"
          theme="transparent"
        />
        <Popover
          className="birthdayPicker__labelPopover"
          data-testid="birthdayPicker__label__popover"
          id={birthdayPickerLabelPopover}
          isVisible={showPopover}
          onDismiss={() => setShowPopover(false)}
          placement="top"
          targetRef={popoverButtonRef}
        >
          {t('birthdayPicker.yourBirthdayIsUsed')}
        </Popover>
      </div>
    );
  };

  return (
    <div className={className}>
      <Label />
      <div className="birthdayPicker__inputs">
        <Select
          centeredLabel={!month}
          error={!!error}
          floatingLabel={!!month}
          label={t('birthdayPicker.month')}
          labelClassName="birthdayPicker__placeholderText"
          name="month"
          onChange={value => setMonth(value)}
          value={month}
          wrapperClassName="birthdayPicker__month"
          aria-describedby={inputDescribedBy}
          aria-label={t('birthdayPicker.birthdayMonth')}
        >
          {months}
        </Select>
        <Input
          aria-describedby={inputDescribedBy}
          aria-label={t('birthdayPicker.dateOfBirth')}
          error={!!error}
          floatingLabel
          label={t('birthdayPicker.day')}
          max={31}
          min={1}
          name="day"
          onChange={e => {
            // limit day input to 2 characters to conform with the expected MM/DD/YYYY date format
            if (e.target.value.length <= 2) setDay(e.target.value);
          }}
          placeholder={t('birthdayPicker.day')}
          type="number"
          value={day}
        />
        <Input
          aria-describedby={inputDescribedBy}
          aria-label={t('birthdayPicker.dateOfBirth')}
          error={!!error}
          floatingLabel
          label={t('birthdayPicker.year')}
          max={new Date().getFullYear()}
          min={BIRTHDAY_MIN_YEAR}
          name="year"
          onChange={e => {
            // limit year input to 4 characters to conform with the expected MM/DD/YYYY date format
            if (e.target.value.length <= 4) setYear(e.target.value);
          }}
          placeholder={t('birthdayPicker.year')}
          type="number"
          value={year}
        />
      </div>
      <ErrorHandler className="fk-externalLabel -error" error={error} id="birthdayPicker-error" showError />
    </div>
  );
};

BirthdayPicker.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default BirthdayPicker;
