import React from 'react';
import { Anchor, Button, Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const SafariCanvasModal = ({ onRequestClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader fk-h2" style={{ marginBottom: '1rem' }}>
        {t('safariCanvasModal.havingTroubleLoggingIn')}
      </h1>
      <p className="fk-modalBody">
        <Trans i18nKey="safariCanvasModal.safariBrowserMayPreventLogin">
          <Anchor data-testid="safariCanvasModal__anchor__infoFlip" to="https://info.flip.com.html" newTab />
        </Trans>
      </p>

      <Modal.Actions className="mt2">
        <Button theme="secondary" data-testid="safariCanvasModal__button__cancel" onClick={onRequestClose}>
          {t('common.close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SafariCanvasModal;
