// Logs a page action to New Relic, used for event-based analytics
// name: string, name of the action being logged
// extras (optional): object, any key in it becomes queryable in NR. Avoid NR default key names
export function logAction(name, extras = {}) {
  if (!window || !window.newrelic || !window.newrelic.addPageAction) return;
  window.newrelic.addPageAction(name, extras);
}

// Logs a custom error to New Relic
// error: string | Error, description of the error or the Error object itself
// extras (optional): object, any key in it becomes queryable in NR. Avoid NR default key names
// level (optional): string, severity level of error
export function logError(error, extras = {}, level = 'error') {
  if (process.env.NODE_ENV === 'development') console.error(error); // log to console during local development

  if (!window || !window.newrelic || !window.newrelic.noticeError) return;

  const err = typeof error === 'object' ? error : new Error(error);
  const extraData = extras;
  if (typeof extraData === 'object') extraData.level = level;
}
