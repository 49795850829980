import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { uaDetect, Button, Input, IcFluentEyeHide24Filled, IcFluentEyeShow24Regular } from '@flipgrid/flipkit';

const PasswordInput = ({ id, label, onChange, value, 'aria-live': ariaLive, ...rest }) => {
  const { t } = useTranslation();
  const [inputType, setInputType] = useState('password');
  const style = { right: uaDetect.safari() ? '40px' : '10px' };
  return (
    <Input
      type={inputType}
      id={id}
      label={label ? label : t('common.password')}
      placeholder={t('passwordInput.enterYourPassword')}
      onChange={onChange}
      autoCapitalize="none"
      inputButton={
        <Button
          theme="clear"
          icon={inputType === 'password' ? <IcFluentEyeShow24Regular /> : <IcFluentEyeHide24Filled />}
          onClick={() => {
            setInputType(inputType === 'password' ? 'text' : 'password');
          }}
          style={style}
          aria-pressed={inputType === 'text'}
          aria-label={inputType === 'password' ? t('passwordInput.revealPassword') : t('passwordInput.hidePassword')}
          data-testid="passwordInput__button__reveal"
        />
      }
      {...rest}
    />
  );
};

PasswordInput.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default PasswordInput;
