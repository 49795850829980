import React from 'react';
import PropTypes from 'prop-types';

const Announcer = ({ children }) => {
  return (
    <div className="announcer" role="alert" aria-live="assertive" aria-atomic="true">
      {children}
    </div>
  );
};

Announcer.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Announcer;
