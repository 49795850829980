import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Anchor, ButtonSubmit, Input } from '@flipgrid/flipkit';

import Announcer from '../Shared/Announcer';
import Navbar from '../Shared/Navbar';

import { resetPassword } from '../../actions/auth.actions';
import { addFocusListener, removeFocusListener } from '../../helpers/focusListener';

function ForgotPassword(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(props.error ? t('forgotPassword.passwordResetLinkExpired') : '');
  const [emailSent, setEmailSent] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    addFocusListener();
    return removeFocusListener;
  }, [addFocusListener, removeFocusListener]);

  const submit = e => {
    e.preventDefault();
    if (!email) {
      setError(t('shared.youMustEnterAnEmailAddress'));
      return;
    }
    setSubmitted(true);
    resetPassword(email)
      .then(() => {
        setEmailSent(true);
      })
      .catch(err => {
        setSubmitted(false);
        setError(t('shared.somethingWentWrong'));
      });
  };

  const announcerText =
    (submitted && !emailSent && t('shared.sendingEmail')) ||
    (submitted && emailSent && t('shared.emailSentFollowTheInstructions')) ||
    '';

  return (
    <div className="forgotPassword">
      <Navbar />
      <div className="forgotPassword__panel">
        {!emailSent && (
          <>
            <h1>{t('forgotPassword.resetYourPassword')}</h1>
            <p>{t('forgotPassword.enterYourEmailForLinkToResetPassword')}</p>
            <form onSubmit={submit} className="forgotPassword__form">
              <Input
                id="forgot-password-email"
                type="email"
                placeholder={t('shared.enterYourEmailAddress')}
                label={t('common.emailAddress')}
                error={error}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
              <ButtonSubmit
                theme="primary"
                className="forgotPassword__formSubmit"
                data-testid="forgotPassword__button__submit"
                variant="block"
                size="52"
                loading={submitted}
              >
                {t('shared.sendEmail')}
              </ButtonSubmit>
            </form>
          </>
        )}
        {emailSent && (
          <>
            <h1>{t('shared.emailSent')}</h1>
            <p>{t('shared.followTheInstructions')}</p>
            <Anchor
              to="/educator"
              theme="primary"
              data-testid="forgotPassword__anchor__returnToLogin"
              className="forgotPassword__returnToLogin"
              variant="block"
              size="52"
            >
              {t('shared.returnToSignIn')}
            </Anchor>
          </>
        )}
      </div>
      <Announcer>{announcerText}</Announcer>
    </div>
  );
}

ForgotPassword.propTypes = {
  flash: PropTypes.object,
};

export default ForgotPassword;
