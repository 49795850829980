import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonSubmit, Input, Smubble } from '@flipgrid/flipkit';
import { useHistory } from 'react-router-dom';

import BirthdayPicker from '../Shared/BirthdayPicker';
import CountryPicker from '../Shared/CountryPicker';
import Navbar from '../Shared/Navbar';
import { validate } from '../../actions/signup.actions';
import { fetchAndRedirect } from '../../helpers/api';
import { flowStates } from '../../constants/states';

function SignUp({
  inviter,
  user,
  profile_submit_path: profileSubmitPath,
  profile_submit_method: profileSubmitMethod,
  profile_submit_options: profileSubmitOptions = {},
}) {
  const { t } = useTranslation();
  const [profile, setProfile] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    country_code: user?.country_code,
    dob: null,
    inviter: inviter,
  });
  const [profileErrors, setProfileErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [showInviter] = useState(!!inviter);
  const history = useHistory();

  const onChange = (name, value) => {
    const newErrors = { ...profileErrors };
    delete newErrors[name];
    setProfile({ ...profile, [name]: value });
    setProfileErrors(newErrors);
  };

  const submit = e => {
    e.preventDefault();
    if (submitting) return;

    const errors = validate(profile);

    if (Object.keys(errors).length) {
      setProfileErrors(errors);
      return;
    }

    setSubmitting(true);

    let csrfToken = document.querySelector('[name="csrf-token"]').content;
    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    };
    const formData = {
      ...profile,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...profileSubmitOptions,
    };

    return fetchAndRedirect(profileSubmitPath, {
      method: profileSubmitMethod,
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(formData),
    }).then(function (data) {
      setSubmitting(false);
      // Handle error
      const errors = {};
      if (data?.errors) {
        ['first_name', 'last_name', 'country_code'].forEach(field => {
          if (data.errors[field] && data.errors[field][0]) errors[field] = data.errors[field][0];
        });
        if (data.errors.dob) errors.dob = 'Please enter a valid birthday';
        if (Object.keys(errors).length) setProfileErrors(errors);
      }
    });
  };

  useEffect(() => {
    // deprecation note- If this user is attempting to sign-up, redirect to sign in
    history.push('/signin');
  }, [history]);


  return (
    <div className="signUp__pageWrapper">
      <Navbar flow={flowStates.signUp} />
      <div className="signUp__wrapper">
        <div className="signUp__flow">
          <div className="signUp__welcome">
            <img
              className="signUp__welcomeImg"
              alt=""
              src="https://static.flip.com/fg-pngs/LeadRegister.png?size=medium"
            />
            <div className="signUp__step">{t('signUp.stepTwoOfTwo')}</div>
          </div>

          <div className="signUp__panel">
            <Smubble height={80} className="showBelowSmall" />
            <h1>{!!user.first_name ? t('signUp.hi', { name: user.first_name }) : t('signUp.hiThere')}</h1>
            <p className="signUp__text">{t('signUp.letsPersonalizeExperience')}</p>
            <form onSubmit={submit} className="signUp__form">
              {!user.first_name || profileErrors.first_name ? (
                <Input
                  id="signup-first-name"
                  type="name"
                  name="first_name"
                  label={t('common.firstName')}
                  placeholder={t('shared.enterYourFirstName')}
                  wrapperClassName="signUp__formInput"
                  maxLength={255}
                  onChange={e => onChange(e.target.name, e.target.value)}
                  value={profile.first_name || ''}
                  error={profileErrors.first_name}
                />
              ) : null}
              {!user.last_name || profileErrors.last_name ? (
                <Input
                  id="signup-last-name"
                  type="name"
                  name="last_name"
                  label={t('common.lastName')}
                  placeholder={t('shared.enterYourLastName')}
                  wrapperClassName="signUp__formInput"
                  maxLength={255}
                  onChange={e => onChange(e.target.name, e.target.value)}
                  value={profile.last_name || ''}
                  error={profileErrors.last_name}
                />
              ) : null}
              <BirthdayPicker
                className="signUp__formInput"
                error={profileErrors.dob}
                onChange={value => onChange('dob', value)}
              />
              <CountryPicker
                wrapperClassName="signUp__countryDropdown"
                value={profile.country_code}
                onChange={value => onChange('country_code', value)}
                error={profileErrors.country_code}
              />
              {showInviter ? (
                <Input
                  id="signup-inviter"
                  type="text"
                  name="inviter"
                  label={t('signUp.communityBuilderCode')}
                  placeholder={t('signUp.communityBuilderCodeOptional')}
                  wrapperClassName="signUp__formInput"
                  onChange={e => onChange(e.target.name, e.target.value)}
                  value={profile.inviter}
                />
              ) : null}
              <ButtonSubmit
                disabled={!profile.first_name || !profile.last_name || !profile.dob || !profile.country_code}
                theme="primary"
                variant="block"
                loading={submitting}
                data-testid="signUp__button__submit"
              >
                {t('common.done')}
              </ButtonSubmit>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

SignUp.propTypes = {
  inviter: PropTypes.string,
  user: PropTypes.object.isRequired,
  profile_submit_path: PropTypes.string.isRequired,
  profile_submit_method: PropTypes.string.isRequired,
  profile_submit_options: PropTypes.object,
};

export default SignUp;
