import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { fetchCountries } from '../../helpers/api';
import { logError } from '../../helpers/logging';

import { Select, Option } from '@flipgrid/flipkit';

export const CountryPicker = ({ wrapperClassName, value, onChange, error }) => {
  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetchCountries() // TODO: can pass localization parameter to use translations provided by backend
      .then(countriesData => {
        setCountries(countriesData);
      })
      .catch(logError);
  }, []);
  const CountryOptions = useMemo(() => {
    return countries.map(country => (
      <Option key={country.code} value={country.code}>
        {t(`countries.${country.code.toLowerCase()}` || country.name)}
      </Option>
    ));
  }, [countries]);

  return (
    <Select
      id="country-code"
      wrapperClassName={wrapperClassName}
      name="country-code"
      label={t('shared.countryOrRegion')}
      onChange={value => onChange(value || null)}
      value={value || 'placeholder'}
      error={error}
    >
      {!value && (
        <Option key="placeholder" value="placeholder" disabled>
          {t('shared.chooseYourCountryOrRegion')}
        </Option>
      )}
      {CountryOptions}
    </Select>
  );
};

CountryPicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default CountryPicker;
