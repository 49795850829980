import React from 'react';
import { Trans } from 'react-i18next';

const PasswordRequirements = () => {
  return (
    <p className="passwordRequirements">
      <Trans i18nKey="passwordRequirements.minCharactersAndMustContain">
        Minimum <strong>8 characters</strong> and must contain <strong>2 of the following:</strong> Uppercase,
        Lowercase, Number, or Symbol.
      </Trans>
    </p>
  );
};

export default PasswordRequirements;
