import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ActionGroup, Button } from '@flipgrid/flipkit';

import { fetchAndRedirect } from '../../helpers/api';

const scopeMap = {
  'read_topics': 'View your Flip topics and associated group name.',
  'User.read': 'View your Flip user account information.',
};

function Authorization({ clientName, clientId, responseType, redirectUri, scopes, first_name, last_name, email, state }) {
  const { t } = useTranslation();

  const approve = e => {
    var path = '/authorizations';
    let csrfToken = document.querySelector('[name="csrf-token"]').content;

    let body = {
      client_id: clientId,
      response_type: responseType,
      redirect_uri: redirectUri,
      approve: true,
      'csrf-token': csrfToken,
      state: state,
    };

    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    };

    return fetchAndRedirect(path, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
  };

  const deny = e => {
    var path = '/authorizations';
    let csrfToken = document.querySelector('[name="csrf-token"]').content;

    let body = {
      client_id: clientId,
      response_type: responseType,
      redirect_uri: redirectUri,
      'csrf-token': csrfToken,
    };

    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    };

    return fetchAndRedirect(path, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
  };

  return (
    <div className="authorization">
      <div className="authorization__panel">
        <h1>{t('authorization.connectFlip')}</h1>
        <p>{t('authorization.nameAndEmail', { name: first_name || last_name, emailAddress: email })}</p>
        <Trans i18nKey="authorization.clientNeedsYourPermission">
          <p>
            <b>{{ client: clientName }}</b> needs your permission to access your Flip account. This will allow{' '}
            {{ client: clientName }} to:
          </p>
        </Trans>
        {scopes && (
          <ul>
            {scopes.split(',').map(scope => {
              return (
                <li key={scope}>
                  <i>{scopeMap[scope]}</i>
                </li>
              );
            })}
          </ul>
        )}
        <ActionGroup stack>
          <Button
            theme="primary"
            data-testid="authorization__button__allow"
            onClick={approve}
            size="52"
            variant="block"
          >
            {t('common.allow')}
          </Button>
          <Button
            theme="secondary"
            className="mt1"
            data-testid="authorization__button__cancel"
            onClick={deny}
            size="52"
            variant="block"
          >
            {t('common.cancel')}
          </Button>
        </ActionGroup>
      </div>
    </div>
  );
}

Authorization.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  scopes: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default Authorization;
