import { useEffect } from 'react';
import { initAnalytics } from '../../helpers/analytics';
import '../../scss/index';
import { initI18n } from '../../config/i18n';

function Scripts({ analyticsKey }) {
  useEffect(() => {
    initI18n();
    initAnalytics(analyticsKey);
  }, []);

  return null;
}

export default Scripts;
