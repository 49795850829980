import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { fetch_token } from '../../../actions/credentials.actions';
import GoogleIcon from '../../../svg/GoogleIcon';
import GenericSsoButton from './GenericSsoButton';

class GoogleSsoButton extends PureComponent {
  constructor(props) {
    super(props);
    this.response = this.response.bind(this);
  }

  response(err, resp) {
    if (resp['code'] == null) {
      return false;
    }
    let body = {
      code: resp['code'],
      client_id: this.props.authId,
      redirect_uri: this.redirect_uri(),
      auth_flow: this.props.auth_flow,
    };
    let path = '/sso/google_oauth2/callback';
    if (this.props.onResponse) this.props.onResponse(resp);
    return fetch_token(path, body);
  }

  redirect_uri() {
    let redirect_uri = null;
    if (this.props.flow == 'popup') {
      redirect_uri = window.location.origin + '/sso';
    } else {
      redirect_uri = window.location.origin + '/sso/google_oauth2/callback';
    }
    return redirect_uri;
  }

  render() {
    const { clientId, text, ariaLabel, flow, auth_flow, disabled = false } = this.props;
    return (
      <GenericSsoButton
        authorizationUrl="https://accounts.google.com/o/oauth2/auth"
        clientId={clientId}
        callback={this.response}
        redirectUri={this.redirect_uri()}
        scope="profile email"
        prompt="select_account"
        classes="-google"
        name={text}
        aria-label={ariaLabel}
        response_type="code"
        icon={<GoogleIcon />}
        referrerMatch="google"
        flow={flow || 'popup'}
        provider="google_oauth2"
        auth_flow={auth_flow || 'user'}
        disabled={disabled}
      />
    );
  }
}

GoogleSsoButton.propTypes = {
  ariaLabel: PropTypes.string,
  authId: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  flow: PropTypes.string,
  onResponse: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  auth_flow: PropTypes.string,
  disabled: PropTypes.bool
};

GoogleSsoButton.defaultProps = {
  ariaLabel: 'Google',
  text: 'Google',
};

export default GoogleSsoButton;
