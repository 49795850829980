import Cookies from 'universal-cookie';
import { uaDetect } from '@flipgrid/flipkit';

export function getCookie(name) {
  const cookies = new Cookies();
  return cookies.get(name);
}

export function removeCookie(name) {
  const cookies = new Cookies();
  return cookies.remove(name);
}

export function setCookie(name, value, options = {}) {
  const cookies = new Cookies();
  const opts = {
    ...options,
    secure: true,
    ...(uaDetect.browserIsSameSiteNoneCookieCompatible() && { sameSite: 'none' }),
  };
  cookies.set(name, value, opts);
}
