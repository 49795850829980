import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { logError } from '../helpers/logging';
import translationEn from '../locales/en/main.json';
import translationEs from '../locales/es/main.json';
import translationEsMx from '../locales/es-MX/main.json';
import translationFr from '../locales/fr/main.json';
import translationFrCa from '../locales/fr-CA/main.json';
import translationJa from '../locales/ja/main.json';
import translationPtBr from '../locales/pt-BR/main.json';

// the translations
const resources = {
  en: {
    translation: translationEn,
  },
  es: {
    translation: translationEs,
  },
  'es-MX': {
    translation: translationEsMx,
  },
  fr: {
    translation: translationFr,
  },
  'fr-CA': {
    translation: translationFrCa,
  },
  ja: {
    translation: translationJa,
  },
  'pt-BR': {
    translation: translationPtBr,
  },
};

const configuration = {
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss
    // Intl NumberFormat expects a Unicode BCP 47 locale identifier which differs from what we currently have
    format: (value, format, lng) => {
      if (format === 'number') {
        return new Intl.NumberFormat(lng).format(value);
      }
      return value;
    },
  },
  // calls save missing key function on backend if key not found
  saveMissing: true,
  // function(lng, ns, key, fallbackValue) { } used for custom  missing key handling (needs saveMissing set to true!)
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    if (lng.includes('en')) logError(`i18next::translator: missingKey ${lng} ${ns} ${key} ${fallbackValue}`);
  },
  debug: process.env.NODE_ENV === 'development',
};

i18n.use(initReactI18next); // passes i18n down to react-i18next

export const initI18n = () => {
  // https://github.com/i18next/i18next-browser-languageDetector
  // navigator: detect and set language based on browser language
  configuration.detection = { order: ['navigator'] };
  i18n.use(HttpApi).use(LanguageDetector);
  i18n.init(configuration);
};

export default i18n;
