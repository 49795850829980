import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import qs from 'querystring';

import PassportDemoSsoButton from '../SignIn/components/PassportDemoSsoButton';
import PassportStgSsoButton from '../SignIn/components/PassportStgSsoButton';
import PassportDevSsoButton from '../SignIn/components/PassportDevSsoButton';
import PassportSsoButton from '../SignIn/components/PassportSsoButton';

const VALID_REFERRERS = ['passport_demo', 'passport_stg', 'passport_dev'];

class EducatorSso extends PureComponent {
  constructor(props) {
    super(props);
    this.setError = this.setError.bind(this);

    this.state = {
      hasError: false,
    };
  }

  checkForReferrer() {
    const searchStr = window.location.search.substring(1).replace(/\/$/, '');
    const params = qs.parse(searchStr);
    const referrer = params['referrer'] || params['sso_provider'] || '';

    return VALID_REFERRERS.find(ref => referrer === ref) || 'passport';
  }

  setError(bool) {
    this.setState({ hasError: bool });
  }

  render() {
    const { t } = this.props;
    const buttons = [];
    const referrer = this.checkForReferrer() || '';
    let loading = false;

    if (!!referrer) {
      switch (referrer) {
        case 'passport_dev':
          // loading = true
          buttons.push(
            <PassportDevSsoButton
              key="passport"
              authId={this.props.authId}
              flow="redirect"
              setError={this.setError}
              auth_flow="educator"
            />,
          );
          break;
        case 'passport_stg':
          // loading = true
          buttons.push(
            <PassportStgSsoButton
              key="passport"
              authId={this.props.authId}
              flow="redirect"
              setError={this.setError}
              auth_flow="educator"
            />,
          );
          break;
        case 'passport_demo':
          // loading = true
          buttons.push(
            <PassportDemoSsoButton
              hide
              key="passport"
              authId={this.props.authId}
              flow="redirect"
              setError={this.setError}
              auth_flow="educator"
            />,
          );
          break;
        case 'passport':
          // loading = true
          buttons.push(
            <PassportSsoButton
              key="passport"
              hide
              authId={this.props.authId}
              flow="redirect"
              setError={this.setError}
              auth_flow="educator"
            />,
          );
          break;
        default:
          break;
      }
    }

    return (
      <div className="signIn">
        <div className="signIn__container">
          {this.state.hasError ? (
            <>
              <h1>{t('shared.signInFailed')}</h1>
              <p>{t('shared.pleaseCloseTheWindow')}</p>
            </>
          ) : (
            <>
              {loading ? <div className="fg-loader" /> : <h1>{t('educatorSso.signInToContinue')}</h1>}
              <div className="signIn__buttons">{buttons}</div>
            </>
          )}
        </div>
      </div>
    );
  }
}

EducatorSso.propTypes = {
  authId: PropTypes.string.isRequired,
  flash: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EducatorSso);
