export const fetchAndRedirect = async (url, options) => {
  const fullOptions = {
    ...options,
    headers: { ...(options.headers || {}), Accept: 'application/json' },
  };
  const response = await fetch(url, fullOptions);
  if (response.redirected) {
    // TODO: can be removed after change is deployed
    // keeping around for backwards compatability
    //  in case request goes to pod running old backend during deployment
    window.location.href = response.url;
    return;
  }
  const responsePayload = await response.json();
  if (response.ok) {
    // server indicates where the user should be directed to next, via a response body object
    window.location.href = responsePayload.next.url;
    return;
  } else if (response.status === 401) {
    window.location.href = `/signin${window.location.search}`;
    return
  }
  return responsePayload;
};

export const fetchData = async path => {
  const response = await fetch(path, {
    headers: {
      Accept: 'application/json',
    },
  });
  const responsePayload = await response.json();
  return responsePayload && responsePayload.data;
};

export const fetchConfigs = () => fetchData('/api/configs');

export const fetchFeatures = async () => {
  const configs = await fetchConfigs();
  return configs.features;
};

export const fetchCountries = () => fetchData('/api/enum/countries');
