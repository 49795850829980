import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { QosPlugin } from '@microsoft/1ds-qos-js';

const isProd = () => {
  !!window.location.host.match(/^(\w|-|\w+\.)*flip(grid)?\.com$/);
};

const appInsights = new ApplicationInsights();
const qosPlugin = new QosPlugin();
const config = {
  channelConfiguration: {
    // Post channel configuration
    eventsLimitInMem: 50,
  },
  propertyConfiguration: {
    // Properties Plugin configuration
    env: isProd() ? 'PROD' : 'PPE',
    userAgent: 'Custom User Agent',
  },
  webAnalyticsConfiguration: {
    // Web Analytics Plugin configuration
    autoCapture: {
      scroll: true,
      pageView: true,
      onLoad: true,
      onUnload: true,
      click: true,
      resize: true,
      jsError: true,
    },
    urlCollectQuery: true,
  },
  extensions: [qosPlugin],
  extensionConfig: {
    [qosPlugin.identifier]: {
      manageCv: true,
      disableAjaxTracking: false,
      // Don't track new relic data
      excludeRequestFromAutoTrackingPatterns: ['^https://bam.nr-data.net/'],
    },
  },
};

export const initAnalytics = key => {
  if (!key) return;

  config.instrumentationKey = key;
  //Initialize SDK
  appInsights.initialize(config, []);

  // This telemetry initializer ensures that we don't log any tokens or credentials from the user
  // examples: AWS access tokens or api signatures
  appInsights.addTelemetryInitializer(envelope => {
    const { baseData } = envelope;

    if (baseData && envelope.name === 'Ms.Web.OutgoingRequest') {
      if (baseData.properties) {
        baseData.name = baseData.properties.HttpMethod ?? 'Unknown"';
      }

      const url = new URL(baseData.target);
      url.searchParams.forEach((_, key) => {
        if (
          key.toLowerCase().indexOf('token') !== -1 ||
          key.toLowerCase().indexOf('signature') !== -1 ||
          key.toLowerCase().indexOf('credential') !== -1
        ) {
          url.searchParams.set(key, '<redacted>');
          baseData.target = url.toString();
        }
      });
    }
  });
};
