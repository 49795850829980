import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Anchor, Smubble } from '@flipgrid/flipkit';

import AppleSsoButton from '../../SignIn/components/AppleSsoButton';
import GoogleSsoButton from '../../SignIn/components/GoogleSsoButton';
import MicrosoftSsoButton from '../../SignIn/components/MicrosoftSsoButton';
import TermsAndPrivacy from '../../Shared/TermsAndPrivacy';

function SignUpTab({ idpClientIds, setSSOComplete }) {
  const { t } = useTranslation();

  return (
    <div className="signUpTab__wrapper">
      <div className="signUpTab__welcome">
        <img
          className="signUpTab__welcomeImg"
          alt=""
          src="https://static.flip.com/fg-pngs/LeadSignIn.png?size=medium"
        />
        <div className="signUpTab__step">{t('signUpTab.stepOneOfTwo')}</div>
      </div>
      <div className="signUpTab">
        <Smubble height={80} className="showBelowSmall" />
        <h1>{t('signUpTab.welcomeToFlip')}</h1>
        <p className="signUpTab__tip">{t('signUpTab.useSchoolEmailBestExp')}</p>
        <div className="signUpTab__buttonContainer">
          <MicrosoftSsoButton
            ariaLabel={t('signUpTab.signUpWithMicrosoft')}
            clientId={idpClientIds.microsoft}
            flow="redirect"
            text={t('signUpTab.signUpWithMicrosoft')}
            auth_flow="educator"
            onResponse={() => {
              setSSOComplete(true);
            }}
            disabled
          />
          <GoogleSsoButton
            ariaLabel={t('signUpTab.signUpWithGoogle')}
            clientId={idpClientIds.google}
            flow="redirect"
            text={t('signUpTab.signUpWithGoogle')}
            auth_flow="educator"
            onResponse={() => {
              setSSOComplete(true);
            }}
            disabled
          />
          <AppleSsoButton
            ariaLabel={t('signUpTab.signUpWithApple')}
            clientId={idpClientIds.apple}
            flow="redirect"
            text={t('signUpTab.signUpWithApple')}
            auth_flow="educator"
            onResponse={() => {
              setSSOComplete(true);
            }}
            disabled
          />
        </div>
        <p className="signUpTab__existingAccount">
          <Trans i18nKey="signUpTab.alreadyHaveAnAccount">
            Already signed up?
            <Anchor to={`/educator${window.location.search}`} data-testid="signUpTab__anchor__backToSignIn">
              Log in
            </Anchor>
          </Trans>
        </p>
        <div>
          <TermsAndPrivacy style={{ color: 'var(--fk-color__foreground-1)' }} tag="span" />
        </div>
      </div>
    </div>
  );
}

SignUpTab.propTypes = {
  idpClientIds: PropTypes.object.isRequired,
  setSSOComplete: PropTypes.func.isRequired,
};

export default SignUpTab;
