import React from 'react';
import { Trans } from 'react-i18next';
import { Anchor } from '@flipgrid/flipkit';
import PropTypes from 'prop-types';

import { getInfoUrl } from '../../helpers/urls';

const TermsAndPrivacy = ({ style, tag = 'p' }) => {
  const RenderElement = tag ? tag : 'p';

  return (
    <RenderElement className="termsAndPrivacy fk-helpText" style={style}>
      <Trans i18nKey="termsAndPrivacy.byContinuingYouAgree">
        By continuing, you agree to{' '}
        <Anchor
          data-testid="signUpTab__anchor__terms"
          newTab
          theme="legal"
          to={`${getInfoUrl()}/about/trust-and-safety/terms-of-use.html`}
        >
          Flip's Terms of Use
        </Anchor>{' '}
        and{' '}
        <Anchor
          data-testid="signUpTab__anchor__privacy"
          newTab
          theme="legal"
          to={`${getInfoUrl()}/about/trust-and-safety/privacy-policy.html`}
        >
          Privacy Policy
        </Anchor>
        .
      </Trans>
    </RenderElement>
  );
};

TermsAndPrivacy.propTypes = {
  tag: PropTypes.string,
};

export default TermsAndPrivacy;
