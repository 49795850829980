import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

/*
  TODO:  Fix - When using Focus Trap, if you open the Popover, then scroll, 
  then click outside the Popover, you are scrolled back to the Popover before it closes.
  Also handle Focus Trap when there is no focusable element.

  If the targetRef is hidden at certain break points via css, do not use Portal
  unless also hiding the Popover via css at same breakpoints, since it cannot be
  positioned without it's target element visible.
*/

const PopoverInner = ({
  children,
  className,
  id,
  isVisible,
  onDismiss,
  placement = 'auto',
  targetRef,
  useFocusTrap,
}) => {
  const popoverRef = useRef(null);
  // Need to use a callback Ref here
  const [arrowRef, setArrowRef] = useState(null);

  // Close Popover if escaped
  const escapeListener = e => {
    if (e.key === 'Escape') {
      if (isVisible) onDismiss();
    }
  };

  // Close if clicked outside of Popover
  const clickListener = e => {
    const target = e.target;
    const isEventTargetOutsideCallout = popoverRef.current && !popoverRef.current.contains(target);

    if (targetRef.current && isEventTargetOutsideCallout) {
      if (isVisible) onDismiss();
    }
  };

  // Attach and cleanup event listeners
  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  });

  // The usePopper hook, see popper docs for option details
  const { styles, attributes } = usePopper(targetRef.current, popoverRef.current, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  if (!isVisible) return null;

  return (
    <div
      id={id}
      className={`popover ${className || ''}`}
      ref={popoverRef}
      role="tooltip"
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="popover__children">
        {useFocusTrap ? (
          <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>{children}</FocusTrap>
        ) : (
          <>{children}</>
        )}
      </div>
      <div ref={setArrowRef} style={styles.arrow} className="popover__arrow" />
    </div>
  );
};

const Popover = props => {
  // Create container element for Portal
  const [container] = React.useState(() => {
    const el = document.createElement('div');
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  });

  // Portal to optionally avoid css cascade issues
  if (props.usePortal) return createPortal(<PopoverInner {...props} />, container);

  return <PopoverInner {...props} />;
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  placement: PropTypes.string,
  targetRef: PropTypes.object.isRequired,
  useFocusTrap: PropTypes.bool,
  usePortal: PropTypes.bool,
};

export default Popover;
