import qs from 'querystring';

import { fetchAndRedirect } from '../helpers/api';

export function login(username, password, clientId) {
  const csrf = document.querySelector('[name="csrf-token"]').content;
  let search_str = window.location.search.substring(1).replace(/\/$/, '');
  let params = qs.parse(search_str);
  let attrs = {
    username,
    password,
    client_id: clientId,
    grant_type: 'password',
  };

  if (params['redirect_url']) {
    attrs['redirect_url'] = params['redirect_url'];
  }

  let body = JSON.stringify(attrs);

  return fetchAndRedirect(`/educator`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    credentials: 'include',
    body: body,
  });
}

export function resetPassword(email) {
  const csrf = document.querySelector('[name="csrf-token"]').content;
  return fetch(`/passwords`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({ email: email }),
  });
}

export function requestEmailLogin(email) {
  const csrf = document.querySelector('[name="csrf-token"]').content;
  return fetch(`/email_login_links`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({ email: email }),
  });
}

export function updatePassword(body) {
  const csrf = document.querySelector('[name="csrf-token"]').content;
  return fetchAndRedirect(`/passwords`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify(body),
  });
}
