const getDomains = () => window.location.hostname.split('.');

// example: auth.flip.com -> flip.com
// example: localhost -> localhost
// example: auth.flip.local -> flip.local
export const getDomain = () => {
  const domains = getDomains();
  return domains.slice(domains.length - 2, domains.length).join('.');
};

export const getAuthApiUrl = () => `${window.location.origin}/api`;

export const getSignOutUrl = redirectUrl => {
  const signOutEndpoint = `${window.location.origin}/signout`;
  return redirectUrl ? `${signOutEndpoint}?redirect_url=${encodeURIComponent(redirectUrl)}` : signOutEndpoint;
};

export const getApiUrl = () => `https://api.${getDomain()}/api`;

export const getInfoUrl = () => `https://info.${getDomain()}`;

export const getMyUrl = () => `https://my.${getDomain()}`;

export const getAdminUrl = () => `https://admin.${getDomain()}`;

export const getQueryParams = () => new URL(document.location).searchParams;

export const getRedirectUrlParam = () => getQueryParams().get('redirect_url');
