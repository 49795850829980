import qs from 'querystring';
import url from 'url';
import { getMyUrl, getAdminUrl, getRedirectUrlParam } from '../helpers/urls';
import i18n from '../config/i18n';

function oauth2(config) {
  const defaultRedirectUrl = config.auth_flow === 'educator' ? getAdminUrl() : getMyUrl();
  return new Promise((resolve, _) => {
    const redirect_url = getRedirectUrlParam() || defaultRedirectUrl;
    const params = {
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      scope: config.scope,
      display: 'popup',
      response_type: config.response_type,
      // TODO: state should be generated/signed on the server side and passed in via the view
      state: btoa(JSON.stringify({ redirect_url })),
    };
    if (config.nonce) params['nonce'] = config.nonce;
    if (config.prompt) params['prompt'] = config.prompt;
    if (config.response_mode) params['response_mode'] = config.response_mode;
    const url = config.authorizationUrl + '?' + qs.stringify(params);
    resolve({ url: url, config: config });
  });
}

function openPopup({ url, config }) {
  return new Promise((resolve, reject) => {
    const width = config.width || 580;
    const height = config.height || 580;
    const options = {
      width: width,
      height: height,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      left: window.screenX + (window.outerWidth - width) / 2,
    };
    const popup = window.open(url, '_blank', qs.stringify(options, ','));

    if (url === 'about:blank') {
      popup.document.body.innerHTML = 'Loading...';
    }

    resolve({ window: popup, config: config });
  });
}

function pollPopup({ window, config, requestToken }) {
  return new Promise((resolve, reject) => {
    const redirectUri = url.parse(config.redirectUri);
    const redirectUriPath = redirectUri.host + redirectUri.pathname;

    if (requestToken) {
      window.location = config.authorizationUrl + '?' + qs.stringify(requestToken);
    }

    const polling = setInterval(() => {
      if (!window || window.closed) {
      }
      try {
        const popupUrlPath = window.location.host + window.location.pathname;
        if (popupUrlPath === redirectUriPath) {
          if (window.location.search || window.location.hash) {
            const query = qs.parse(window.location.search.substring(1).replace(/\/$/, ''));
            // eslint-disable-next-line
            const hash = qs.parse(window.location.hash.substring(1).replace(/[\/$]/, ''));
            const params = Object.assign({}, query, hash);

            if (params.error) {
              reject({ error: params.error });
            } else {
              resolve({
                oauthData: params,
                config: config,
                window: window,
                interval: polling,
              });
            }
          } else {
            reject({
              error: i18n.t('ssoActions.oauthRedirect'),
            });
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in Internet Explorer.
      }
    }, 500);
  });
}

function closePopup({ window, interval, oauthData }) {
  return new Promise((resolve, reject) => {
    clearInterval(interval);
    window.close();
    resolve(oauthData);
  });
}

function redirect({ url, config }) {
  window.location.href = url;
}

export function loginPopup(config) {
  return oauth2(config).then(openPopup).then(pollPopup).then(closePopup);
}

export function loginRedirect(config) {
  return oauth2(config).then(redirect);
}
