import { BIRTHDAY_MIN_YEAR } from '../constants/constants';
import { getAuthApiUrl } from '../helpers/urls';
import i18n from '../config/i18n';

export function getAccountTypes() {
  return fetch(`${getAuthApiUrl()}/account_types`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        const error = new Error(i18n.t('signUpActions.accountTypeLoadFailed'));
        error.error = response;
        throw error;
      }
      return response;
    });
}

function isValidDate(dob) {
  // using splitter will get the parts of the date
  const splitter = dob.replace(/[0-9]/g, '')[0];

  // extract the parts of the date for validation
  const parts = dob.split(splitter);
  const day = parts[1];
  const year = parts[2];

  // create a date object using the dob
  const date = new Date(dob);

  // validate date -- dob transforms successfully into a Date object and isn't in the future
  const isValidDate = Boolean(+date) && date <= new Date();
  // validate day -- check for leap year violations and dates exceeding month limit
  const isValidDay = date.getDate() == day;
  // validate year -- doesn't make sense if they're over 100 years old
  const isValidYear = BIRTHDAY_MIN_YEAR <= year;
  return isValidDate && isValidDay && isValidYear;
}

export function validateDOB(dob) {
  const dateRegex = /([0-9]{2}\/[0-9]{2}\/[0-9]{4})/g;
  let error = '';

  // Verify DOB is not null
  if (!dob) error = i18n.t('signUpActions.pleaseEnterYourBirthday');
  // Verify the DOB is formatted properly
  else if (!dateRegex.test(dob)) error = i18n.t('signUpActions.birthdayMustBe');
  else if (!isValidDate(dob)) error = i18n.t('signUpActions.pleaseEnterAValidBirthday');

  return error;
}

export function validate(profile) {
  const errors = {};
  // Validate DOB
  const dobValidationError = validateDOB(profile.dob);
  if (dobValidationError) {
    errors.dob = dobValidationError;
  }
  // Validate name
  if (!profile.first_name?.trim()?.length) {
    errors.first_name = i18n.t('signUpActions.pleaseEnterAValidFirstName');
  }
  if (!profile.last_name?.trim()?.length) {
    errors.last_name = i18n.t('signUpActions.pleaseEnterAValidLastName');
  }
  return errors;
}
