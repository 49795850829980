import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GenericSsoButton from './GenericSsoButton';
import { fetch_token } from '../../../actions/credentials.actions';
import MircosoftIcon from '../../../svg/MircosoftIcon';

class MicrosoftGraph extends PureComponent {
  constructor(props) {
    super(props);
    this.response = this.response.bind(this);
  }

  response(err, resp) {
    if (resp['code'] == null) {
      return false;
    }
    let body = {
      code: resp['code'],
      client_id: this.props.authId,
      redirect_uri: this.redirect_uri(),
      auth_flow: this.props.auth_flow,
    };
    let path = '/sso/microsoft_graph/callback';
    if (this.props.onResponse) this.props.onResponse(resp);
    return fetch_token(path, body);
  }

  redirect_uri() {
    let redirect_uri = null;
    if (this.props.flow == 'popup') {
      redirect_uri = window.location.origin + '/sso';
    } else {
      redirect_uri = window.location.origin + '/sso/microsoft_graph/callback';
    }
    return redirect_uri;
  }

  buildAuthorizationUrl = () => {
    const tenant = process.env.MICROSOFT_TENANT || 'common';
    return `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize`;
  };

  render() {
    const { clientId, hide, text, ariaLabel, flow, auth_flow, disabled = false } = this.props;
    return (
      <GenericSsoButton
        authorizationUrl={this.buildAuthorizationUrl()}
        clientId={clientId}
        redirectUri={this.redirect_uri()}
        scope="user.read openid"
        callback={this.response}
        classes={`-microsoft ${hide ? '-hide' : ''}`}
        name={text}
        aria-label={ariaLabel}
        response_type="code"
        prompt="select_account"
        icon={<MircosoftIcon />}
        referrerMatch="microsoft"
        flow={flow || 'popup'}
        provider="microsoft_graph"
        auth_flow={auth_flow || 'user'}
        disabled={disabled}
      />
    );
  }
}

MicrosoftGraph.propTypes = {
  ariaLabel: PropTypes.string,
  authId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  flow: PropTypes.string,
  hide: PropTypes.bool,
  onResponse: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  auth_flow: PropTypes.string,
  disabled: PropTypes.bool
};

MicrosoftGraph.defaultProps = {
  ariaLabel: 'Microsoft',
  hide: false,
  text: 'Microsoft',
};

export default MicrosoftGraph;
