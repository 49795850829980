import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor } from '@flipgrid/flipkit';

import Navbar from '../Shared/Navbar';

// TODO: This page looks bad and we should redo it. Added back what I think were missing styles.
// Test at https://auth.flipgrid-dev.com/educator/suspended

const EducatorSuspended = () => {
  const { t } = useTranslation();

  return (
    <div className="educatorSuspended">
      <Navbar />
      <div className="educatorSuspended__panel">
        <p>{t('educatorSuspended.yourAccountIsSuspended')}</p>
        <details>
          <Trans i18nKey="educatorSuspended.ifYouWantToUnsuspendYourAccount">
            <summary>If you want to unsuspend your account, you can contact our support.</summary>
            We can help you with your account&nbsp;
            <Anchor to="https://aka.ms/heretohelp" newTab>
              here
            </Anchor>
            .
          </Trans>
        </details>
      </div>
    </div>
  );
};

export default EducatorSuspended;
