import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Anchor, Button } from '@flipgrid/flipkit';

import Navbar from '../Shared/Navbar';
import { flowStates } from '../../constants/states';
import { getSignOutUrl, getInfoUrl, getMyUrl } from '../../helpers/urls';

const BLOCKED_MINOR_HELP_ARTICLE = 'https://aka.ms/studentschoolemail';
const GRAD_CAP = 'https://static.flip.com/fg-pngs/graduation-cap.png';
const POTENTIAL_LEAD_HELP_ARTICLE = 'https://aka.ms/orgsandpersonal';
const SALUTING_FACE = 'https://static.flip.com/fg-pngs/saluting-face.png';
const SMILING_FACE_WITH_SMILING_EYES = 'https://static.flip.com/fg-pngs/smiling-face-with-smiling-eyes.png';

const AccountVerification = ({
  account_type,
  email,
  error,
  existing_user,
  redirect_url,
  mobile_flow,
  state_invalid,
  state_logout,
  user_type,
}) => {
  const { t } = useTranslation();
  const [displaySignOut, setDisplaySignOut] = useState(false);
  const domain = email ? email.substring(email.lastIndexOf('@') + 1) : null;
  const infoUrl = getInfoUrl();
  const myUrl = getMyUrl();

  // condition: existing_user && error === 'invalid_age_group'
  const BlockExistingUser = (
    <>
      <img alt="" className="accountVerification__img" src={`${GRAD_CAP}?size=small`} />
      <h1 className="fk-h2 accountVerification__h1">{t('accountVerification.useYourSchoolEmail')}</h1>
      <p className="accountVerification__p__blockExistingUser">
        <Trans i18nKey="accountVerification.toManageYourExistingVideos">
          To manage your existing videos, contact your lead. Still confused? Find help&nbsp;
          <Anchor data-testid="accountVerification__link__help" to={BLOCKED_MINOR_HELP_ARTICLE} theme="primary">
            here
          </Anchor>
          .
        </Trans>
      </p>
      <Anchor
        className="accountVerification__button"
        data-testid="accountVerification__anchor__signOut"
        size="44"
        theme="primary"
        to={getSignOutUrl(infoUrl)}
        variant="button"
      >
        {t('accountVerification.tryAgain')}
      </Anchor>
    </>
  );

  // condition: !existing_user && error === 'invalid_age_group'
  const BlockNewUser = (
    <>
      <img alt="" className="accountVerification__img" src={`${GRAD_CAP}?size=small`} />
      <h1 className="fk-h2 accountVerification__h1">{t('accountVerification.areYouAStudent')}</h1>
      <p className="accountVerification__p__blockNewUser">
        <Trans i18nKey="accountVerification.stillConfused">
          Use your school email for school groups. Still confused? Find help&nbsp;
          <Anchor data-testid="accountVerification__link__help" theme="primary" to={BLOCKED_MINOR_HELP_ARTICLE}>
            here
          </Anchor>
          .
        </Trans>
      </p>
      <Anchor
        className="accountVerification__anchor"
        data-testid="accountVerification__anchor__signOut"
        size="44"
        theme="primary"
        to={getSignOutUrl(infoUrl)}
        variant="button"
      >
        {t('accountVerification.goBack')}
      </Anchor>
    </>
  );

  // condition: account_type === 'lead' && error === 'invalid_age_group'
  const BlockOrgPotentialLead = (
    <>
      <img alt="" className="accountVerification__img" src={`${SMILING_FACE_WITH_SMILING_EYES}?size=small`} />
      <h1 className="fk-h2 accountVerification__h1">{t('accountVerification.createGroupsWithYourPersonalEmail')}</h1>
      <p className="accountVerification__p__blockOrgPotentialLead">
        {domain ? (
          <Trans i18nKey="accountVerification.youAreSignedInWithAnOrgEmailDomain">
            You're signed in with a <b>{{ emailDomain: domain }}</b> email address. Make a new account with a personal
            email address to start creating your own groups.&nbsp;
            <Anchor data-testid="accountVerification__link__help" to={POTENTIAL_LEAD_HELP_ARTICLE} theme="primary">
              Learn more.
            </Anchor>
          </Trans>
        ) : (
          <Trans i18nKey="accountVerification.youAreSignedInWithAnOrgEmail">
            You're signed in with an org email address. Make a new account with a personal email address to start
            creating your own groups.&nbsp;
            <Anchor data-testid="accountVerification__link__help" to={POTENTIAL_LEAD_HELP_ARTICLE} theme="primary">
              Learn more.
            </Anchor>
          </Trans>
        )}
      </p>
      {mobile_flow ? (
        <Anchor
          className="accountVerification__button"
          data-testid="accountVerification__button__signOut"
          size="44"
          theme="primary"
          to={`/redirects?redirect_url=${redirect_url}&status=${state_logout}`}
          variant="button"
        >
          {t('accountVerification.createANewAccount')}
        </Anchor>
      ) : (
        <Button
          className="accountVerification__button"
          data-testid="accountVerification__button__signOut"
          onClick={() => setDisplaySignOut(true)}
        >
          {t('accountVerification.createANewAccount')}
        </Button>
      )}
      <br />
      {mobile_flow ? (
        <Anchor
          data-testid="accountVerification__anchor__myFlipgrid"
          theme="subtle"
          to={`/redirects?redirect_url=${redirect_url}&status=${state_invalid}`}
        >
          {t('accountVerification.goBack')}
        </Anchor>
      ) : (
        <Anchor data-testid="accountVerification__anchor__myFlipgrid" theme="subtle" to={`${myUrl}/me`}>
          {t('accountVerification.goBack')}
        </Anchor>
      )}
    </>
  );

  // condition: account_type === 'educator' && error === 'invalid_age_group'
  const BlockOrgUser = (
    <>
      <img alt="" className="accountVerification__img" src={`${SMILING_FACE_WITH_SMILING_EYES}?size=small`} />
      <h1 className="fk-h2 accountVerification__h1">{t('accountVerification.youCanJoinAGroup')}</h1>
      <p className="accountVerification__p__blockOrgUser">
        {domain ? (
          <Trans i18nKey="accountVerification.youAreUsingAnOrgEmailDomain">
            You're using a <b>{{ emailDomain: domain }}</b> email address. Go to your profile to see your videos or join
            a group.
          </Trans>
        ) : (
          t('accountVerification.youAreUsingAnOrgEmail')
        )}
      </p>
      <Anchor
        className="accountVerification__anchor"
        data-testid="accountVerification__anchor__myFlipgrid"
        size="44"
        theme="primary"
        to={myUrl}
        variant="button"
      >
        {t('accountVerification.goToProfile')}
      </Anchor>
    </>
  );

  // condition: error === 'invalid_educator_email'
  const InvalidEmail = (
    <>
      <h1 className="fk-h2">
        {email
          ? t('accountVerification.emailIsNotEligible', { emailAddress: email })
          : t('accountVerification.yourEmailIsNotEligible')}
      </h1>
      <p className="fk-helpText">{t('accountVerification.askYourLeadForAJoinCode')}</p>
      <Anchor
        className="accountVerification__anchor"
        data-testid="accountVerification__anchor__info"
        theme="primary"
        to={infoUrl}
        variant="button"
      >
        {t('accountVerification.goBack')}
      </Anchor>
    </>
  );

  const SigningOut = (
    <>
      <img alt="" className="accountVerification__img" src={`${SALUTING_FACE}?size=small`} />
      <h1 className="fk-h2 accountVerification__h1">{t('accountVerification.signingOut')}</h1>
      <p className="accountVerification__p__signingOut">
        {domain
          ? t('accountVerification.youWillNeedToSignBackInDomain', { emailAddress: email, emailDomain: domain })
          : t('accountVerification.youWillNeedToSignBackIn')}
      </p>
      <Anchor
        className="accountVerification__button"
        data-testid="accountVerification__anchor__signingOut"
        size="44"
        theme="primary"
        to={getSignOutUrl('/signup')}
        variant="button"
      >
        {t('common.continue')}
      </Anchor>
      <br />
      <Anchor data-testid="accountVerification__anchor__myFlipgrid" theme="subtle" to={`${myUrl}/me`}>
        {t('common.cancel')}
      </Anchor>
    </>
  );

  const getBlockScreen = () => {
    let blockScreen = null;
    if (displaySignOut) {
      blockScreen = SigningOut;
    } else {
      if (error === 'invalid_educator_email') {
        blockScreen = InvalidEmail;
      } else {
        // this else block covers all the 'invalid_age_group' cases
        if (user_type === 'organization' && account_type === 'lead') {
          blockScreen = BlockOrgPotentialLead;
        } else if (user_type === 'organization' && account_type === 'educator') {
          blockScreen = BlockOrgUser;
        } else if (existing_user) {
          blockScreen = BlockExistingUser;
        } else if (!existing_user) {
          blockScreen = BlockNewUser;
        }
      }
    }
    return blockScreen;
  };

  return (
    <div className="accountVerification">
      <Navbar flow={flowStates.signUp} />
      <div className="accountVerification__panel">{getBlockScreen()}</div>
    </div>
  );
};

AccountVerification.propTypes = {
  account_type: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  existing_user: PropTypes.bool.isRequired,
  redirect_url: PropTypes.string,
  mobile_flow: PropTypes.bool.isRequired,
  state_invalid: PropTypes.string,
  state_logout: PropTypes.string,
  user_type: PropTypes.string.isRequired,
};

export default AccountVerification;
