import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import qs from 'querystring';

import { getCookie, removeCookie } from '../../helpers/cookies';

import PartyEmoji from '../../svg/PartyEmoji';
import { fetch_token } from '../../actions/credentials.actions';

class Sso extends PureComponent {
  constructor(props) {
    super(props);
    this.referrerRedirect = this.referrerRedirect.bind(this);
  }

  componentDidMount() {
    var provider = getCookie('SSO_PROVIDER');
    removeCookie('SSO_PROVIDER');
    if (provider == 'microsoft_graph') {
      // this.referrerRedirect('microsoft_graph');
    } else if (provider == 'google_oauth2') {
      // this.referrerRedirect('google');
    }
  }

  referrerRedirect(provider, codeFieldName = 'code') {
    const query = qs.parse(window.location.search.substring(1).replace(/\/$/, ''));
    const hash = qs.parse(window.location.hash.substring(1).replace(/[\/$]/, ''));
    const params = Object.assign({}, query, hash);

    const body = {
      code: params[codeFieldName],
      client_id: this.props.authId,
      redirect_uri: window.location.origin + '/sso',
    };
    const path = '/sso/' + provider + '/callback';

    fetch_token(path, body);
  }

  render() {
    const { t } = this.props;
    const query = qs.parse(window.location.search.substring(1).replace(/\/$/, ''));
    const hash = qs.parse(window.location.hash.substring(1).replace(/[\/$]/, ''));
    const params = Object.assign({}, query, hash);
    const hasError = params['error'];

    return (
      <div className="sso">
        <div className="sso__container">
          {hasError ? (
            <>
              <h1>{t('shared.signInFailed')}</h1>
              <p>{t('shared.pleaseCloseTheWindow')}</p>
            </>
          ) : (
            <h1>
              {t('sso.signInSuccessful')} <PartyEmoji />
            </h1>
          )}
        </div>
      </div>
    );
  }
}

Sso.propTypes = {
  authId: PropTypes.string.isRequired,
};

export default withTranslation()(Sso);
