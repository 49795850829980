const handleKeydown = e => {
  const KEY_CODES = [
    // Tab
    9,
    // Enter
    13,
    // Esc
    27,
    // Arrows
    37,
    38,
    39,
    40,
  ];
  if (KEY_CODES.indexOf(e.keyCode) > -1) {
    document.body.classList.add('-focusVisible');
  }
};
const handleMouseup = () => {
  document.body.classList.remove('-focusVisible');
};

export const addFocusListener = () => {
  document.addEventListener('keydown', handleKeydown, true);
  document.addEventListener('mouseup', handleMouseup, true);
};

export const removeFocusListener = () => {
  document.removeEventListener('keydown', handleKeydown, true);
  document.removeEventListener('mouseup', handleMouseup, true);
};
