/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PartyEmoji = ({ classes, size }) => {
  const [altId] = useState(Math.random());
  const partyEmojiId = 'partyEmoji-' + altId;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classes} width={size} height={size} viewBox="0 0 64 64">
      <linearGradient
        id={'A-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="241.281"
        y1="-921.513"
        x2="270.985"
        y2="-921.513"
        gradientTransform="rotate(-134.999 335.66 -384.931)"
      >
        <stop offset="0" stopColor="#e6a400" />
        <stop offset=".015" stopColor="#e7a502" />
        <stop offset=".273" stopColor="#f0b520" />
        <stop offset=".528" stopColor="#f7c136" />
        <stop offset=".773" stopColor="#fcc843" />
        <stop offset="1" stopColor="#fdca47" />
      </linearGradient>
      <path
        fill={'url(#A-' + partyEmojiId + ')'}
        d="M12.664 29.522L2.033 61.204c-.158.472.277.933.758.759l30.461-11.021-20.588-21.42z"
      />
      <linearGradient
        id={'B-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="241.043"
        y1="-905.848"
        x2="271.108"
        y2="-905.848"
        gradientTransform="rotate(-134.999 335.66 -384.931)"
      >
        <stop offset="0" stopColor="#e6a400" />
        <stop offset=".015" stopColor="#e7a502" />
        <stop offset=".273" stopColor="#f0b520" />
        <stop offset=".528" stopColor="#f7c136" />
        <stop offset=".773" stopColor="#fcc843" />
        <stop offset="1" stopColor="#fdca47" />
      </linearGradient>
      <path
        fill={'url(#B-' + partyEmojiId + ')'}
        d="M27.412 35.79c5.816 5.924 8.818 12.471 6.707 14.622-2.113 2.151-8.545-.905-14.363-6.829-5.816-5.923-8.818-12.47-6.705-14.622 2.113-2.152 8.543.906 14.361 6.829z"
      />
      <path
        fill="#EA8800"
        d="M13.566 46.067l.233-3.162-2.672-1.701 3.076-.755.793-3.069 1.668 2.694 3.16-.196-2.043 2.42 1.164 2.948-2.933-1.196z"
      />
      <path
        fill="#F59E01"
        d="M9.748 47.612l.195-.696-.521-.5.721-.029.312-.651.254.677.715.099-.565.447.127.711-.601-.399zM11.508 37.983l.193-.696-.519-.5.72-.03.313-.65.252.677.717.097-.565.449.127.711-.601-.399zM16.43 49.835l.191-.696-.519-.499.72-.032.315-.649.252.676.715.098-.565.449.127.712-.602-.399zM10.568 57.229l.077-.717-.598-.408.707-.15.203-.691.361.624.721-.022-.484.536.244.681-.658-.295zM24.246 53.471l.029-.634-.545-.324.612-.168.138-.618.35.529.631-.059-.395.495.25.582-.593-.221z"
      />
      <path fill="#EA8800" d="M15.514 54.949l-2.821 1.441 2.803.977 3.959-1.433 1.4-2.83-3.031.916-2.242-2.239z" />
      <path
        fill="#F59E01"
        d="M7.188 53.101l.947-1.212-.617-1.41 1.445.527 1.148-1.025-.054 1.54 1.33.776-1.481.424-.326 1.503-.861-1.276z"
      />
      <path fill="#EA8800" d="M2.381 60.167l1.933-2.137 2.758.694-1.404-2.474 1.514-2.408-2.893.638z" />
      <path
        fill="#F59E01"
        d="M22.547 52.19l.154-2.097-1.773-1.132 2.041-.5.525-2.037 1.11 1.787 2.099-.129-1.357 1.606.771 1.958-1.947-.794z"
      />
      <radialGradient
        id={'C-' + partyEmojiId}
        cx="255.727"
        cy="-905.41"
        r="15.793"
        gradientTransform="matrix(-.7131 -.7133 .6179 -.6181 768.908 -341.036)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#94500e" />
        <stop offset=".172" stopColor="#8c4d11" />
        <stop offset=".438" stopColor="#764519" />
        <stop offset=".765" stopColor="#523827" />
        <stop offset=".873" stopColor="#45332c" />
      </radialGradient>
      <path
        fill={'url(#C-' + partyEmojiId + ')'}
        d="M27.307 35.896c5.705 5.806 8.691 12.178 6.678 14.233-2.018 2.052-8.277-.988-13.982-6.795-5.703-5.802-8.691-12.174-6.674-14.229 2.015-2.056 8.276.986 13.978 6.791z"
      />
      <linearGradient
        id={'D-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="354.375"
        y1="145.809"
        x2="367.203"
        y2="145.809"
        gradientTransform="scale(-1 1) rotate(-33.585 -63.569 756.369)"
      >
        <stop offset="0" stopColor="#53daf2" />
        <stop offset=".209" stopColor="#4ed5f1" />
        <stop offset=".456" stopColor="#40c7ed" />
        <stop offset=".722" stopColor="#28afe7" />
        <stop offset=".999" stopColor="#078ede" />
        <stop offset="1" stopColor="#078ede" />
      </linearGradient>
      <path
        fill={'url(#D-' + partyEmojiId + ')'}
        d="M43.297 8.201c2.109-1.133 10.277 6.073 6.041 11.929-.299.416 2.01-1.215 1.867-.787 5.561-6.18-5.105-15.365-6.387-13.436-1.392 2.096-.13.197-1.521 2.294z"
      />
      <linearGradient
        id={'E-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="354.356"
        y1="-868.139"
        x2="367.546"
        y2="-868.139"
        gradientTransform="scale(-1) rotate(33.585 -1268.873 -1120.117)"
      >
        <stop offset="0" stopColor="#078ede" />
        <stop offset=".075" stopColor="#1199e2" />
        <stop offset=".332" stopColor="#2fbaef" />
        <stop offset=".578" stopColor="#45d2f8" />
        <stop offset=".806" stopColor="#52e0fd" />
        <stop offset="1" stopColor="#57e5ff" />
      </linearGradient>
      <path
        fill={'url(#E-' + partyEmojiId + ')'}
        d="M44.816 5.905c-.631 3.616 10.121 5.602 14.182 1.698-3.537 7.521-15.086 5.421-16.313 1.511-.294-.949.741-1.112 2.131-3.209z"
      />
      <linearGradient
        id={'F-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="354.303"
        y1="156.785"
        x2="367.13"
        y2="156.785"
        gradientTransform="scale(-1 1) rotate(-33.585 -63.569 756.369)"
      >
        <stop offset="0" stopColor="#53daf2" />
        <stop offset=".209" stopColor="#4ed5f1" />
        <stop offset=".456" stopColor="#40c7ed" />
        <stop offset=".722" stopColor="#28afe7" />
        <stop offset=".999" stopColor="#078ede" />
        <stop offset="1" stopColor="#078ede" />
      </linearGradient>
      <path
        fill={'url(#F-' + partyEmojiId + ')'}
        d="M37.443 17.149c2.105-1.13 10.641 5.604 6.289 12.785-.268.444 1.764-2.077 1.619-1.644 5.559-6.181-5.105-15.365-6.387-13.437-1.392 2.098-.128.2-1.521 2.296z"
      />
      <linearGradient
        id={'G-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="354.307"
        y1="-879.775"
        x2="367.443"
        y2="-879.775"
        gradientTransform="scale(-1) rotate(33.585 -1268.873 -1120.117)"
      >
        <stop offset="0" stopColor="#078ede" />
        <stop offset=".075" stopColor="#1199e2" />
        <stop offset=".332" stopColor="#2fbaef" />
        <stop offset=".578" stopColor="#45d2f8" />
        <stop offset=".806" stopColor="#52e0fd" />
        <stop offset="1" stopColor="#57e5ff" />
      </linearGradient>
      <path
        fill={'url(#G-' + partyEmojiId + ')'}
        d="M38.963 14.854c-.406 3.563 9.193 6.656 13.266 2.979-3.801 8.31-14.58 3.959-15.393.23-.213-.967.736-1.112 2.127-3.209z"
      />
      <linearGradient
        id={'H-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="354.1"
        y1="-898.607"
        x2="367.659"
        y2="-898.607"
        gradientTransform="scale(-1) rotate(33.585 -1268.873 -1120.117)"
      >
        <stop offset="0" stopColor="#57e5ff" />
        <stop offset=".194" stopColor="#52e0fd" />
        <stop offset=".422" stopColor="#45d2f8" />
        <stop offset=".668" stopColor="#2fbaef" />
        <stop offset=".925" stopColor="#1199e2" />
        <stop offset="1" stopColor="#078ede" />
      </linearGradient>
      <path
        fill={'url(#H-' + partyEmojiId + ')'}
        d="M46.121 27.256c-6.584 13.668-18.979 6.527-24.922 13.393 7.24-10.909 17.172-4.555 24.922-13.393z"
      />
      <linearGradient
        id={'I-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="45.773"
        y1="97.889"
        x2="54.909"
        y2="97.889"
        gradientTransform="scale(-1 1) rotate(-67.058 -47.135 145.783)"
      >
        <stop offset=".23" stopColor="#aa5bef" />
        <stop offset=".491" stopColor="#a858ee" />
        <stop offset=".66" stopColor="#a250e9" />
        <stop offset=".803" stopColor="#9641e1" />
        <stop offset=".931" stopColor="#872cd7" />
        <stop offset="1" stopColor="#7c1dcf" />
      </linearGradient>
      <path
        fill={'url(#I-' + partyEmojiId + ')'}
        d="M52.438 32.725c1.695.157 3.719 7.648-1.096 9.462-.342.129 1.67.067 1.418.265 5.729-1.486 3-11.134 1.482-10.492-1.648.7-.156.066-1.804.765z"
      />
      <linearGradient
        id={'J-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="45.756"
        y1="-821.706"
        x2="55.15"
        y2="-821.706"
        gradientTransform="rotate(-112.942 337.635 -394.974)"
      >
        <stop offset="0" stopColor="#7c1dcf" />
        <stop offset=".069" stopColor="#872cd7" />
        <stop offset=".197" stopColor="#9641e1" />
        <stop offset=".34" stopColor="#a250e9" />
        <stop offset=".509" stopColor="#a858ee" />
        <stop offset=".77" stopColor="#aa5bef" />
      </linearGradient>
      <path
        fill={'url(#J-' + partyEmojiId + ')'}
        d="M54.242 31.96c-1.795 1.899 3.813 7.304 7.758 6.579-5.055 3.08-11.092-2.706-10.283-5.51.195-.68.875-.37 2.525-1.069z"
      />
      <linearGradient
        id={'K-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="45.719"
        y1="105.705"
        x2="54.856"
        y2="105.705"
        gradientTransform="scale(-1 1) rotate(-67.058 -47.135 145.783)"
      >
        <stop offset=".23" stopColor="#aa5bef" />
        <stop offset=".491" stopColor="#a858ee" />
        <stop offset=".66" stopColor="#a250e9" />
        <stop offset=".803" stopColor="#9641e1" />
        <stop offset=".931" stopColor="#872cd7" />
        <stop offset="1" stopColor="#7c1dcf" />
      </linearGradient>
      <path
        fill={'url(#K-' + partyEmojiId + ')'}
        d="M45.445 35.742c1.695.155 4.119 7.511-1.283 10.066-.334.158 1.861-.541 1.607-.34 5.729-1.487 3-11.134 1.48-10.491-1.647.699-.153.066-1.804.765z"
      />
      <linearGradient
        id={'L-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="45.72"
        y1="-829.994"
        x2="55.078"
        y2="-829.994"
        gradientTransform="rotate(-112.942 337.635 -394.974)"
      >
        <stop offset="0" stopColor="#7c1dcf" />
        <stop offset=".069" stopColor="#872cd7" />
        <stop offset=".197" stopColor="#9641e1" />
        <stop offset=".34" stopColor="#a250e9" />
        <stop offset=".509" stopColor="#a858ee" />
        <stop offset=".77" stopColor="#aa5bef" />
      </linearGradient>
      <path
        fill={'url(#L-' + partyEmojiId + ')'}
        d="M47.25 34.977c-1.641 1.956 2.848 7.567 6.711 6.98-5.521 3.445-10.217-3.377-9.236-5.911.254-.657.875-.37 2.525-1.069z"
      />
      <linearGradient
        id={'M-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="45.573"
        y1="-843.403"
        x2="55.231"
        y2="-843.403"
        gradientTransform="rotate(-112.942 337.635 -394.974)"
      >
        <stop offset=".23" stopColor="#aa5bef" />
        <stop offset=".491" stopColor="#a858ee" />
        <stop offset=".66" stopColor="#a250e9" />
        <stop offset=".803" stopColor="#9641e1" />
        <stop offset=".931" stopColor="#872cd7" />
        <stop offset="1" stopColor="#7c1dcf" />
      </linearGradient>
      <path
        fill={'url(#M-' + partyEmojiId + ')'}
        d="M46.633 45.155c-9.279 5.532-13.838-3.579-20.066-1.836 8.585-3.634 11.992 4.042 20.066 1.836z"
      />
      <linearGradient
        id={'N-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="118.93"
        y1="170.269"
        x2="127.609"
        y2="170.269"
        gradientTransform="rotate(27.834 397.427 -105.285)"
      >
        <stop offset="0" stopColor="#ff000e" />
        <stop offset="1" stopColor="#b80000" />
      </linearGradient>
      <path
        fill={'url(#N-' + partyEmojiId + ')'}
        d="M30.631 11.526c-.01-1.621-6.936-4.144-9.043.269-.15.312.072-1.586-.137-1.365 1.871-5.303 10.778-1.937 10.049-.552-.797 1.506-.076.142-.869 1.648z"
      />
      <linearGradient
        id={'O-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="118.915"
        y1="-894.271"
        x2="127.84"
        y2="-894.271"
        gradientTransform="scale(1 -1) rotate(-27.834 1865.433 -258.54)"
      >
        <stop offset="0" stopColor="#cf0000" />
        <stop offset="1" stopColor="#ff000e" />
      </linearGradient>
      <path
        fill={'url(#O-' + partyEmojiId + ')'}
        d="M31.5 9.878C29.559 11.423 24.898 5.677 25.902 2c-3.322 4.538 1.664 10.722 4.381 10.184.66-.131.42-.8 1.217-2.306z"
      />
      <linearGradient
        id={'P-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="118.884"
        y1="177.697"
        x2="127.561"
        y2="177.697"
        gradientTransform="rotate(27.834 397.427 -105.285)"
      >
        <stop offset="0" stopColor="#ff000e" />
        <stop offset="1" stopColor="#b80000" />
      </linearGradient>
      <path
        fill={'url(#P-' + partyEmojiId + ')'}
        d="M27.209 17.9c-.01-1.617-6.775-4.511-9.631.399-.178.305.662-1.72.453-1.492 1.869-5.305 10.779-1.939 10.047-.555l-.869 1.648z"
      />
      <linearGradient
        id={'Q-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="118.883"
        y1="-902.145"
        x2="127.772"
        y2="-902.145"
        gradientTransform="scale(1 -1) rotate(-27.834 1865.433 -258.54)"
      >
        <stop offset="0" stopColor="#cf0000" />
        <stop offset="1" stopColor="#ff000e" />
      </linearGradient>
      <path
        fill={'url(#Q-' + partyEmojiId + ')'}
        d="M28.078 16.253c-1.982 1.396-6.928-3.308-6.063-6.92-3.703 4.949 2.367 9.947 4.846 9.226.645-.189.424-.8 1.217-2.306z"
      />
      <linearGradient
        id={'R-' + partyEmojiId}
        gradientUnits="userSpaceOnUse"
        x1="118.746"
        y1="-914.888"
        x2="127.925"
        y2="-914.888"
        gradientTransform="scale(1 -1) rotate(-27.834 1865.433 -258.54)"
      >
        <stop offset="0" stopColor="#cf0000" />
        <stop offset="1" stopColor="#ff000e" />
      </linearGradient>
      <path
        fill={'url(#R-' + partyEmojiId + ')'}
        d="M18.396 16.014c-5.986 8.337 2.266 13.393.111 19.148 4.136-7.835-2.851-11.682-.111-19.148z"
      />
      <path
        fill="#E635DE"
        d="M27.957 28.04l.52-2.529-1.981-1.655 2.565-.287.962-2.395 1.065 2.351 2.574.175-1.904 1.739.627 2.503-2.242-1.274z"
      />
      <path
        fill="#3CC3EC"
        d="M57.719 25.979l.32-1.557-1.219-1.018 1.578-.177.592-1.475.656 1.447 1.586.109-1.173 1.071.388 1.541-1.381-.784z"
      />
      <path
        fill="#A6E639"
        d="M35.057 8.673l1.162-1.428-.705-1.699 1.716.665 1.401-1.197-.104 1.839 1.571.96-1.78.471-.427 1.792-.998-1.547z"
      />
      <path
        fill="#E81C27"
        d="M38.779 41.517l1.164-1.427-.705-1.7 1.717.665 1.399-1.197-.104 1.839 1.572.961-1.781.471-.428 1.791-.996-1.547z"
      />
      <path fill="#A6E639" d="M34.918 35.707l1.508 2.74-2.205 2.203-1.055-2.856z" />
      <path fill="#3CC3EC" d="M23.082 29.462l1.508 2.741-2.203 2.205-1.057-2.858z" />
      <path fill="#E81C27" d="M51.395 28.899l-1.827 1.986-2.406-1.197 2.045-1.648z" />
      <path fill="#E635DE" d="M15.611 6.738l2.303 2.337-1.596 2.853-1.88-2.583z" />
      <path fill="#A6E639" d="M51.582 48.359l-2.393 1.245-1.855-1.945 2.484-.853z" />
    </svg>
  );
};

PartyEmoji.defaultProps = {
  size: 35,
  classes: '',
};

PartyEmoji.propTypes = {
  size: PropTypes.number,
  classes: PropTypes.string,
};

export default React.memo(PartyEmoji);
