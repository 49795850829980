import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@flipgrid/flipkit';

import Navbar from '../Shared/Navbar';
import TermsAndPrivacy from '../Shared/TermsAndPrivacy';
import { fetchAndRedirect } from '../../helpers/api';

// Currently we mark users terms accepted during sign up, so this component isn't rendered often.
// But if an existing user didn't accept the terms, the auth flow will redirect the user to this component.

const TermsOfUse = () => {
  const { t } = useTranslation();
  const confirmTermsOfUse = () => {
    var path = '/terms';
    let csrfToken = document.querySelector('[name="csrf-token"]').content;

    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    };

    fetchAndRedirect(path, {
      method: 'POST',
      headers: headers,
    });
  };

  return (
    <div className="termsOfUse">
      <Navbar />
      <div className="termsOfUse__panel">
        <h1>{t('termsOfUse.createAVideo')}</h1>
        <p>
          <Trans i18nKey="termsOfUse.forEducatorsOnly">
            <b>For educators only.</b> Flip makes it easy for educators to create and share quick videos with the world!
            To get started, view the Flip Terms of Use and Privacy Policy, and then select Continue to create your
            account.
          </Trans>
        </p>

        <Button
          theme="primary"
          onClick={confirmTermsOfUse}
          className="termsOfUse__continueBtn"
          data-testid="termsOfUse__button__continue"
        >
          {t('common.continue')}
        </Button>

        <TermsAndPrivacy />
      </div>
    </div>
  );
};

TermsOfUse.propTypes = {};

export default TermsOfUse;
