import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GenericSsoButton from './GenericSsoButton';
import { fetch_token } from '../../../actions/credentials.actions';
import CarnegieLearningLogo from '../../../svg/CarnegieLearningLogo';

class PassportStg extends PureComponent {
  constructor(props) {
    super(props);
    this.response = this.response.bind(this);
  }

  response(err, resp) {
    let body = { code: resp['code'], client_id: this.props.authId, auth_flow: this.props.auth_flow };
    let path = '/api/oauth2/passport_stg/callback';

    fetch_token(path, body);
  }

  render() {
    let redirect_uri = null;
    if (this.props.flow == 'popup') {
      redirect_uri = window.location.origin + '/sso';
    } else {
      redirect_uri = window.location.origin + '/sso/passport_stg/callback';
    }

    return (
      <GenericSsoButton
        authorizationUrl="https://passport.qa-cli.com/#/oauth/authorize"
        clientId="2" // yes it is just 2
        redirectUri={redirect_uri}
        scope="email,user_location"
        callback={this.response}
        classes="-passport"
        name="Carnegie Learning"
        icon={<CarnegieLearningLogo />}
        flow={this.props.flow || 'popup'}
        provider="passport"
        auth_flow={this.props.auth_flow || 'user'}
      />
    );
  }
}

PassportStg.propTypes = {
  authId: PropTypes.string.isRequired,
};

export default PassportStg;
