import React from "react";

const MicrosoftIcon = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    width="38px"
    height="38px"
    viewBox="28.214 8 100 100"
  >
    <g>
      <path
        fill="#F25022"
        d="M28.214,8h47.572c-0.003,15.857,0,31.714-0.003,47.57c-15.854,0-31.711,0-47.569,0V8L28.214,8z"
      />
      <path
        fill="#80BA01"
        d="M80.643,8h47.571c0,15.857,0.003,31.714-0.002,47.57c-15.854-0.002-31.712,0-47.565,0
        C80.64,39.714,80.643,23.857,80.643,8L80.643,8z"
      />
      <path
        fill="#02A4EF"
        d="M28.214,60.426c15.857,0.006,31.714-0.003,47.572,0.006c0.002,15.856,0,31.711,0,47.568H28.214V60.426
        L28.214,60.426z"
      />
      <path
        fill="#FFB902"
        d="M80.646,60.432c15.854-0.006,31.711-0.002,47.567-0.002c0,15.856,0,31.713,0,47.57H80.643
        C80.646,92.143,80.64,76.286,80.646,60.432L80.646,60.432z"
      />
    </g>
  </svg>
);

export default React.memo(MicrosoftIcon);
