import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Anchor, AnchorNav, SmubbleWordmark } from '@flipgrid/flipkit';

import { flowStates } from '../../constants/states';
import { getInfoUrl } from '../../helpers/urls';

const Navbar = ({ flow }) => {
  const { t } = useTranslation();
  return (
    <nav className="navbar">
      <SmubbleWordmark height={32} />

      {!!flow && (
        <div className="navbar__right">
          <AnchorNav
            to={`${getInfoUrl()}/blog.html`}
            className="navbar__link"
            data-testid="navbar__anchor__blog"
            newTab
          >
            {t('navbar.blog')}
          </AnchorNav>
          <AnchorNav to="https://help.flipgrid.com" className="navbar__link" data-testid="navbar__anchor__help" newTab>
            {t('navbar.helpCenter')}
          </AnchorNav>
          <AnchorNav
            to={`${getInfoUrl()}/about.html`}
            className="navbar__link"
            data-testid="navbar__anchor__resources"
            newTab
          >
            {t('navbar.resources')}
          </AnchorNav>
          {flow === flowStates.signIn && (
            <Anchor
              variant="button"
              theme="primary"
              size="36"
              to="/signup"
              className="navbar__signUp"
              data-testid="navbar__anchor__signup"
            >
              {t('navbar.signUpToday')}
            </Anchor>
          )}
        </div>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  flow: PropTypes.string,
};

export default Navbar;
