import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import keys from 'object-keys';
import { Button } from '@flipgrid/flipkit';

import { setCookie } from '../../../helpers/cookies';
import { loginPopup, loginRedirect } from '../../../actions/sso.actions';

class GenericSso extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.launchFlow = this.launchFlow.bind(this);
  }

  handleClick() {
    // TODO - pass in the props individually instead
    this.launchFlow(this.props.flow);
  }

  launchFlow(flow) {
    let expiry = new Date();
    expiry.setTime(expiry.getTime() + 5 * 60000); // 5 minutes
    setCookie('SSO_PROVIDER', this.props.provider, { expires: expiry });

    if (flow == 'redirect') {
      loginRedirect(this.props).then(
        res => {
          this.props.callback(null, res);
        },
        error => {
          this.props.callback(error, null);
        },
      );
    } else {
      loginPopup(this.props).then(
        res => {
          this.props.callback(null, res);
        },
        error => {
          this.props.callback(error, null);
        },
      );
    }
  }

  componentDidMount() {
    let search_str = window.location.search.substring(1).replace(/\/$/, '');
    let params = qs.parse(search_str);
    let referrer = params['referrer'];
    if (this.props.referrerMatch && referrer && referrer.match(this.props.referrerMatch)) {
      this.launchFlow(this.props.flow);
    }
  }

  getAttributesForButton() {
    return keys(this.props).reduce((acc, prop) => {
      if (['style', 'disabled', 'aria-label'].some(wantedProp => wantedProp === prop)) {
        acc[prop] = this.props[prop];
      }
      return acc;
    }, {});
  }

  render() {
    const buttonAttrs = this.getAttributesForButton();
    return (
      <Button
        {...buttonAttrs}
        theme="tertiary"
        variant="block"
        className={`genericSsoButton ${this.props.classes}`}
        onClick={this.handleClick}
        icon={this.props.icon}
        size="52"
      >
        {this.props.name}
      </Button>
    );
  }
}

GenericSso.propTypes = {
  redirectUri: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  scope: PropTypes.string,
  callback: PropTypes.func.isRequired,
  flow: PropTypes.string,
  provider: PropTypes.string,
  auth_flow: PropTypes.string,
};

GenericSso.defaultProps = {
  clientId: '',
  redirectUri: 'http://localhost:3000/',
  authorizationUrl: '',
  scope: 'id,name,gender,email,location',
  width: 580,
  height: 400,
  response_type: 'code',
  referrerMatch: null,
  flow: 'redirect',
  auth_flow: 'user',
};

export default GenericSso;
