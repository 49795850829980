import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loader } from '@flipgrid/flipkit';

import Navbar from '../Shared/Navbar';
import Announcer from '../Shared/Announcer';
import SignInTab from './Tabs/SignInTab';
import SignUpTab from './Tabs/SignUpTab';

import { flowStates } from '../../constants/states';
import { addFocusListener, removeFocusListener } from '../../helpers/focusListener';

function EducatorAuth({ authId, idpClientIds, defaultFlow }) {
  const { t } = useTranslation();
  const flow = defaultFlow === 'signin' ? flowStates.signIn : flowStates.signUp;
  const isSignUpFlow = flow === flowStates.signUp;
  const [ssoComplete, setSSOComplete] = useState(false);

  useEffect(() => {
    addFocusListener();
    return removeFocusListener;
  }, [addFocusListener, removeFocusListener]);

  return (
    <>
      <div className="educatorAuth">
        <Navbar flow={flow} />
        {ssoComplete ? (
          <div className="educatorAuth__ssoLoading">
            <Loader container />
            {t('shared.preparingYourFlipExperience')}
          </div>
        ) : (
          <>
            {flow === flowStates.signIn ? (
              <SignInTab authId={authId} idpClientIds={idpClientIds} setSSOComplete={setSSOComplete} />
            ) : null}
            {isSignUpFlow ? (
              <SignUpTab authId={authId} idpClientIds={idpClientIds} setSSOComplete={setSSOComplete} />
            ) : null}
          </>
        )}
        <Announcer>{ssoComplete && t('shared.preparingYourFlipExperience')}</Announcer>
      </div>
    </>
  );
}

EducatorAuth.propTypes = {
  authId: PropTypes.string.isRequired,
  idpClientIds: PropTypes.object.isRequired,
  defaultFlow: PropTypes.string,
};

export default EducatorAuth;
