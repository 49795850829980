import React, { useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import {
  uaDetect,
  Anchor,
  Button,
  ButtonSubmit,
  IcFluentChevronDown24Regular,
  IcFluentMailUnread24Regular,
  Input,
  Smubble,
} from '@flipgrid/flipkit';

import { login } from '../../../actions/auth.actions';
import AppleSsoButton from '../../SignIn/components/AppleSsoButton';
import GoogleSsoButton from '../../SignIn/components/GoogleSsoButton';
import MicrosoftSsoButton from '../../SignIn/components/MicrosoftSsoButton';
import PasswordInput from '../../Shared/PasswordInput';
import TermsAndPrivacy from '../../Shared/TermsAndPrivacy';
import SafariCanvasModal from '../../Modals/SafariCanvasModal';

function SignInTab({ authId, idpClientIds, setSSOComplete }) {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showSafariCanvasModal, setShowSafariCanvasModal] = useState(false);
  const emailInputRef = useRef(null);
  const legacyFormRef = useRef(null);

  useEffect(() => {
    function checkIfFramedOnCanvas() {
      if (window.self !== window.top) {
        // The page is in an iframe, and the iframe is Canvas
        const referrer = document.referrer;
        return referrer.includes('canvas');
      }
    }

    const isIframedSafariCanvas = checkIfFramedOnCanvas();
    setShowSafariCanvasModal(isIframedSafariCanvas);
  }, []);

  useEffect(() => {
    if (showForm) {
      emailInputRef.current.focus();
      if (uaDetect.mobile() && legacyFormRef) {
        const top = legacyFormRef.current.offsetTop;
        try {
          window.scrollTo({
            top,
            behavior: 'smooth',
          });
        } catch (error) {
          window.scrollTo(0, top);
        }
      }
    }
  }, [showForm]);

  const onChange = e => {
    // Set new form state, clear error
    setFormState({ ...formState, [e.target.name]: e.target.value });
    setError(null);
  };

  const submit = e => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    login(formState.email, formState.password, authId).then(response => {
      setSubmitting(false);
      if (response && response.error) {
        let error = response.error_description || t('shared.somethingWentWrong');
        if (response.error_description === 'Invalid email and password combination.') {
          error = t('signInTab.incorrectEmailOrPassword');
        }
        setError(error);
      }
    });
  };

  return (
    <CSSTransition in={showForm} timeout={200} classNames="signInTab__transition">
      <div className="signInTab__wrapper">
        <div className="signUpTab__welcome">
          <img
            className="signUpTab__welcomeImg"
            alt=""
            src="https://static.flip.com/fg-pngs/LeadSignIn.png?size=medium"
          />
        </div>
        <div className="signInTab">
          {/* signUpTab */}
          <Smubble height={80} className="showBelowSmall" />
          <h1>{t('common.signIn')}</h1>
          <p className="signInTab__suggestSchoolEmail">{t('shared.suggestSchoolEmail')}</p>
          <div className="signInTab__ssoContainer">
            <MicrosoftSsoButton
              ariaLabel={t('shared.signInWithMicrosoft')}
              authId={authId}
              clientId={idpClientIds.microsoft}
              flow="redirect"
              text={t('shared.continueWithMicrosoft')}
              auth_flow="educator"
              onResponse={() => {
                setSSOComplete(true);
              }}
            />
            <GoogleSsoButton
              ariaLabel={t('shared.signInWithGoogle')}
              authId={authId}
              clientId={idpClientIds.google}
              flow="redirect"
              text={t('shared.continueWithGoogle')}
              auth_flow="educator"
              onResponse={() => {
                setSSOComplete(true);
              }}
            />
            <div className="signInTab__googleLoginIssues">
              <Trans i18nKey="signInTab.studentGoogleLoginIssues">
                Are you a Google educator?
                <Anchor
                  data-testid="signInTab__anchor__googleSignupProblems"
                  to="https://help.flip.com/hc/en-us/articles/17005477431959-Enable-Flip-for-use-with-Google-Workspace-for-Education-"
                  newTab
                >
                  Share this link with your IT administrator to prevent any interruptions!
                </Anchor>
              </Trans>
            </div>
            <AppleSsoButton
              ariaLabel={t('shared.signInWithApple')}
              authId={authId}
              clientId={idpClientIds.apple}
              flow="redirect"
              text={t('shared.continueWithApple')}
              auth_flow="educator"
              onResponse={() => {
                setSSOComplete(true);
              }}
            />
          </div>
          <div className="signInTab__legacyForm">
            <hr />
            <Button
              theme="clear"
              variant="block"
              iconLeft={<IcFluentMailUnread24Regular />}
              iconRight={<IcFluentChevronDown24Regular className="signInTab__legacyPasswordArrow" />}
              className="signInTab__legacyPassword"
              actionInnerClassName="signInTab__legacyPasswordInner"
              data-testid="signInTab__button__showForm"
              onClick={() => setShowForm(current => !current)}
              aria-expanded={showForm}
              ref={legacyFormRef}
            >
              {t('signInTab.signInWithADifferentEmail')}
            </Button>
            <div className="signInTab__formContainer" role="region">
              <form className="signInTab__form" onSubmit={submit}>
                <Input
                  name="email"
                  type="email"
                  label={t('common.email')}
                  placeholder={t('shared.enterYourEmailAddress')}
                  ref={emailInputRef}
                  onChange={onChange}
                  error={!!error}
                />
                <PasswordInput
                  id="sign-in-tab-password"
                  wrapperClassName="signInTab__formInput"
                  name="password"
                  autoComplete="current-password"
                  onChange={onChange}
                  value={formState.password}
                  error={error}
                />
                <div className="signInTab__formInfo">
                  <Anchor
                    to="/passwords/forgot"
                    className="signInTab__forgotPassword"
                    aria-label={t('signInTab.goToForgotPasswordPage')}
                    data-testid="signInTab__anchor__forgotPassword"
                  >
                    {t('signInTab.forgotPassword')}
                  </Anchor>
                </div>

                <ButtonSubmit
                  theme="primary"
                  variant="block"
                  loading={submitting}
                  className={`signInTab__submit ${submitting ? '-submitting' : ''}`}
                  data-testid="signInTab__button__submit"
                >
                  {t('common.signIn')}
                </ButtonSubmit>
              </form>
            </div>
            <hr />
          </div>
          <TermsAndPrivacy />
        </div>
        {showSafariCanvasModal && <SafariCanvasModal onRequestClose={() => setShowSafariCanvasModal(false)} />}
      </div>
    </CSSTransition>
  );
}

SignInTab.propTypes = {
  authId: PropTypes.string.isRequired,
  idpClientIds: PropTypes.object.isRequired,
  setSSOComplete: PropTypes.func.isRequired,
};

export default SignInTab;
